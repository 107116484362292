import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'reactstrap';

import showToasterMessage from '../../../../UI/ToasterMessage/toasterMessage';
import fetchMethodRequest from '../../../../../config/service';
import apiCalls from '../../../../../config/apiCalls';
import { Button as ReactStrapButton } from 'reactstrap';
import DoDateField from '../../../../Form/Fields/DoDateField';
import DoInputField from '../../../../Form/Fields/DoInputField';
import DoInputMask from '../../../../Form/Fields/DoInputMask';
import DoTextareaField from '../../../../Form/Fields/DoTextareaField';
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog } from 'primereact/dialog';
import DoSelectField from '../../../../Form/Fields/DoSelectField';
import DoRadioButtons from '../../../../Form/Fields/DoRadioButtons';
import DoDecimalField from '../../../../Form/Fields/DoDecimalField';
import Loader from '../../../../App/Loader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faPercentage, faPlus, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import config from '../../../../../config/config';

import * as yup from "yup";


function AccountsFormModal(props) {

  const { isOpenFormModal, closeFormModal, type, getUrlData } = props;
  const [formFields, setFormFields] = useState([]);
  const [creditcardFormFields, setCreditcardFormFields] = useState([]);
  const [choosePaymentFormFields, setChoosePaymentFormFields] = useState([]);
  const [patientId, setPatientId] = useState({});
  const [payloadData, setPayloadData] = useState({});
  const [adjustmentsOptions, setAdjustmentsOptions] = useState([]);
  const [schema, setSchema] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState(props.accounts ? props.accounts : []);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState([{ label: 'Cash', value: 'Cash' }]);
  const [depositAmount, setDepositAmount] = useState();
  const [userPaidAmount, setUserPaidAmount] = useState();
  const [userRemainingAmount, setUserRemainingAmount] = useState();
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [outputArray, setOutputArray] = useState([]);
  const [calculateArray, setCalculateArray] = useState([]);
  const [focus, setFocus] = useState(null);
  const [clinicData, setClinicData] = useState({});


  const adjustmentsData = {
    additions: [
      { label: 'Sales Tax', value: 'Sales Tax' },
      { label: 'Misc Pos Adjustment', value: 'Misc Pos Adjustment' },
      { label: 'BROKEN Appt - No Show', value: 'BROKEN Appt - No Show' },
      { label: 'BROKEN Appt w/o 24 hrs Notice', value: 'BROKEN Appt w/o 24 hrs Notice' },
      { label: 'Returned Check Fee', value: 'Returned Check Fee' },
      { label: 'NSF fee', value: 'NSF fee' },
      { label: 'Finance Charge', value: 'Finance Charge' },
      { label: 'Billing Charge', value: 'Billing Charge' },
      { label: 'Late Charge', value: 'Late Charge' }
    ],
    subtractions: [
      { label: 'Professional Discount', value: 'Professional Discount' },
      { label: 'Cash Discount', value: 'Cash Discount' },
      { label: 'Misc Neg Adjustment', value: 'Misc Neg Adjustment' },
      { label: '$10 Referral Discount', value: '$10 Referral Discount' },
      { label: '5% Cash Discount', value: '5% Cash Discount' },
      { label: '10% Senior Discount', value: '10% Senior Discount' },
      { label: 'New Patient Discount', value: 'New Patient Discount' },
      { label: 'Discount', value: 'Discount' }
    ]
  };

  const PaymentCompleteTableFields = [
    // { field: "", header: "Prov" },  
    // { field: "patientName", header: "Patient Name"},
     { field: "providerName", header: "Prov"}, 
     { field: "code", header: "Code"}, 
     { field: "payedAmount", header: "Amount" , fieldType:'Amount' }
  ]

  const outStandingBillsTableFields = [
    // { field: "", header: "Date" },   
    // { field: "patientName", header: "Patient Name"},
     { field: "providerName", header: "Prov"},  { field: "code", header: "Code" },{ field: "fee", header: "AmtOrig" , fieldType:'Amount'}, { field: "unpayedAmount", header: "AmtEnd" , fieldType:'Amount' }

  ]

  let {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    setError,
    formState: { errors },
    control,
    watch
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    
    if (type === 'payment') {
      if(props?.accounts[0]?.totalAmount < 0) {
        getPaymentStatus();
      }  
      const id = localStorage.HospitalData ? JSON.parse(localStorage.HospitalData)._id : {};
      fetchMethodRequest('GET', `${apiCalls.hospitals}/${id}`).then(res => {
        if (res?.details) {
          const hspData = res.details;
          localStorage.setItem('HospitalData', JSON.stringify(hspData));
          const arr = hspData?.paymentTypes && hspData?.paymentTypes?.length > 0 ? hspData.paymentTypes.map(type => ({ label: type, value: type })) : [{ label: 'Cash', value: 'Cash' }];
          setPaymentTypeOptions(arr);
          setFormFieldFun(type, arr);
        }
      })
      if (props.accounts && props.accounts[0]?.patientTreatment) {
        let completedData = [];
        let outStandingData = [];
        props.accounts[0].patientTreatment.map((item) => {
          if (item.isCompletedPayment === 'Completed') {
            completedData.push(item);
          } else if (item.isCompletedPayment === 'Pending') {
            outStandingData.push(item);
          }
        });
      }
    } else {
      setFormFieldFun(type, paymentTypeOptions);
    }
  }, []);



  useEffect(() => {
    updateFormFieldsOptions();
  }, [watch('type'), watch('paymentType'), watch('choosepayment')]);



  const getFormFields = (paymentTypeOptions) => {
    const todayDate = new Date();
    const patientData = localStorage.PatientData ? JSON.parse(localStorage.PatientData) : {};
    setPatientId(patientData);
    const hspData = localStorage.HospitalData ? JSON.parse(localStorage.HospitalData) : {};
    const clinicData = localStorage.ClinicData ? JSON.parse(localStorage.ClinicData) : {};
    setClinicData(clinicData);

    const choosepaymentFields = [
      { name: 'choosepayment', type: 'radio', label: 'Choose your payment', required: true, customWidth: 'col-12 p-2', options: [{ label: 'Pay Here', value: 'Pay Here' }, { label: 'Send Request', value: 'Send Request' }], show: true, displayForm: ['payment'] },
      { name: 'note', type: 'textarea', label: 'Note', placeholder: 'Enter Note', customWidth: 'col-12 p-2', show: false, displayForm: ['adjustment', 'payment'] },  
    ]

    const creditcardformFields = [
      { name: "cardHolderName", type: "text", label: "Card Holder Name", required: true, placeholder: "Card Holder Name", customWidth: "col-12 p-2", show: true, displayForm: ['payment']},
      { name: 'creditcard', type: 'mask', label: 'Card Number', required: true, placeholder: 'Card Number', customWidth: 'col-12 p-2', show: true, displayForm: ['payment'], mask: '9999 9999 9999 9999', maskPlaceholder: "XXXX XXXX XXXX XXXX", nextFocusedElement: "expiry"},
      { name: "expiry", type: 'mask', label: "Expiry", placeholder:"Expiry", required: true, customWidth: 'col-8 p-2', show: true, displayForm: ['payment'], mask: '99/99', maskPlaceholder: "MM YY", nextFocusedElement: "cvv"},
      { name: "cvv", type: 'mask', label: 'CVV', placeholder: 'CVV', required: true, customWidth: 'col-4 p-2', show: true, displayForm: ['payment'], mask: '999', maskPlaceholder: "CVV"}

    ]

    const allFormFields = [
      { name: 'hospital', value: hspData._id, displayForm: ['payment'] },
      { name: 'entryDate', label: 'Entry Date', required: true, type: 'date', value: todayDate, show: true, displayForm: ['adjustment', 'payment'], disabled: true },
      {
        name: 'patientName', label: 'Patient Name', required: true, type: 'text',
        value: patientData.fullName, //value: patientData.name, 
        show: false, displayForm: ['adjustment', 'payment'], disabled: true
      },
      {
        name: 'clinicIdSearch', label: 'Clinic Id Search', required: true, type: 'text',
        value: clinicData.abbrevation, //value: patientData.name, 
        show: false, displayForm: ['adjustment', 'payment'], disabled: true
      },
      { name: 'adjustmentDate', label: 'Adjustment Date', required: true, type: 'date', value: todayDate, show: true, displayForm: ['adjustment'], disabled: true },
      { name: 'procedureDate', label: 'Procedure Date', required: true, type: 'date', value: todayDate, show: true, displayForm: ['adjustment'], disabled: true },
      { name: 'amount', type: 'decimal', label: 'Amount', placeholder: 'Enter Amount ', required: true, show: true, displayForm: ['adjustment', 'payment'] },
      { name: 'type', type: 'radio', label: 'Adjustment Type', required: true, options: [{ label: 'Additions', value: 'additions' }, { label: 'Subtractions', value: 'subtractions' },{ label: 'Refund', value: 'refund' }], show: true, displayForm: ['adjustment'] },
      {
        name: 'adjustment', type: 'dropDown', label: 'Adjustment', required: true,
        options: [],
        placeholder: 'Select Adjustment', show: false, displayForm: ['adjustment']
      },
      {
        name: 'paymentType', type: 'dropDown', label: 'Payment Type', required: true,
        options: paymentTypeOptions,
        alertMessage: paymentTypeOptions.every(type => type.value !== 'Paypal') ? 'Please reach out to the Admin to activate PayPal payments' : null,
        placeholder: 'Select a Payment', show: true, displayForm: ['payment']
      },
      { name: 'chequeNumber', type: 'text', label: 'Cheque Number', required: true, placeholder: 'Enter Cheque Number', show: false, displayForm: ['payment'] },
      { name: "choosepayment", type: "choosepayment", label: "choose payment", customWidth: "col-4", required: true, show: false, displayForm: ["payment"], dependentFields: choosepaymentFields},
      { name: 'creditcard', type: 'creditcard', label: 'Credit Card', required: true, placeholder: 'Credit Card', customWidth: 'col-8 p-2', show: false, displayForm: ['payment'], mask: '9999 9999 9999 9999', maskPlaceholder: "XXXX XXXX XXXX XXXX", dependentFields: creditcardformFields},
      { name: 'note', type: 'textarea', label: 'Note', placeholder: 'Enter Note', customWidth: 'col-4 p-2', show: true, displayForm: ['adjustment', 'payment'] },
      { name: 'code', show: false, value: 'adjust', displayForm: ['adjustment'] },
      { name: 'tableData', type: 'table', label: 'Note', placeholder: 'Enter Note', customWidth: 'col-12 p-2', show: true, displayForm: ['payment'] },

    ];


    setCreditcardFormFields(creditcardformFields);
    setChoosePaymentFormFields(choosepaymentFields)

    const formFields = allFormFields.filter(field => field.displayForm.includes(type));
    return formFields;
  }

  let getValidations = (field) => {
    if (field.type === 'number') {
      return yup.number().required(`${field.label} is a required field`);
    } else if (field.type === "decimal") {
      return yup.number()
        .required(`${field.label} is a required field`)
        .min(0.01, `${field.label} must be greater than 0`);
    } else {
      return yup.string().required(`${field.label} is a required field`);
    }
  }

  const setValidations = (fields, setDefvalues) => {
    let valObj = {};
    fields.forEach((field) => {
      if (field.value && setDefvalues) {
        setValue(field.name, field.value)
      }
      if (field.required && field.show) {
        valObj[field.name] = getValidations(field);
      }
      if(field.show && field.required && field.dependentFields && field?.dependentFields.length > 0){
        field.dependentFields.forEach(depField => {
          if(depField.show && depField.required) {
            valObj[depField.name] = getValidations(depField);
          }
        })
      }
    });
    return yup.object().shape(valObj)
  }

  async function updateFormFieldsOptions() {
    const fields = await formFields;    
    fields.forEach(async (field, i) => {
      // const i = await fields.findIndex(field => field.name === 'adjustment' || field.name === 'chequeNumber' || field.name === 'creditcard')
        if (field.name === 'adjustment') {
          setValue('adjustment', undefined);
          const type = await getValues('type');
          const options = await type ? adjustmentsData[type] : [];
          field.options = await options;
          field.show = type !== "refund"; 
          // setFormFields([...fields]);
          // field.show = true;
  
        } else if (field.name === 'chequeNumber') {
          setValue('chequeNumber', undefined);
          const paymentType = await getValues('paymentType');
          field.show = paymentType === 'Cheque' ? true : false;
        }
        else if(field.name === 'choosepayment'){
          const choosepayment = await getValues("choosepayment");
          if(!choosepayment) {
            let findIndex = fields.findIndex(val => val.name === 'creditcard');
            fields[findIndex].show = false;
          }
          if(choosepayment === "Pay Here") {
            setChoosePaymentFormFields(prev => (prev.map(field => {
              if(field.name === "note") field.show = true;
              return field;
            })))
          }
          if(choosepayment === "Send Request") {
            setChoosePaymentFormFields(prev => (prev.map(field => {
              if(field.name === "note") field.show = false;
              return field;
            })))
          }
          const paymentType = await getValues('paymentType');
          field.show = paymentType === 'Cardknox' ? true : false;
        }
        else if(field.name === 'creditcard'){
          const paymentType = await getValues("paymentType");
          const choosePayment = await getValues('choosepayment');
          if(choosePayment === 'Pay Here' && paymentType === "Cardknox") {
            field.show = true;
            setFormFields(prev => (
              prev.map(field => {
                if(field.name === "note") field.show = false;
                return field;
              }) 
            ))
          }
          else {
            setFormFields(prev => ( prev.map(field => {
              if(field.name === "note") field.show = true;
              return field;
            })))
            field.show = false;
          }
        }



        const tSchema = await setValidations([...fields]);
        setSchema(tSchema);
        setFormFields([...fields]);
    })

  }

  async function setFormFieldFun(type, arr) {
    const formFields = await getFormFields(arr);

    const tSchema = await setValidations(formFields, true);
    setSchema(tSchema);
    setFormFields(formFields);
  }


  let getDate = (i, item) => {

    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoDateField
              markReq={item.required}
              input={field}
              item={item}
              label={item.label}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : "date"}
            />)}
        />
      </div>
    )
  }

  let getDefault = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <DoInputField
                markReq={item.required}
                input={field}
                item={item}
                id={field.id}
                name={field.name}
                label={item.label}
                field={field}
                // type={item.type ? item.type : "text"}
                type={item.type ? item.type : "text"}
                fieldState={fieldState}
                errors={errors}
                defVal={item.value}
                placeholder={item.placeholder ? item.placeholder : null}
              />
            );
          }}
        />
      </div>
    )
  }

  let getTextArea = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoTextareaField
              markReq={item.required}
              input={field}
              id={field.id}
              label={item.label}
              item={item}
              name={field.name}
              field={field}
              defVal={item.value}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    )
  }

  let getDropdown = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoSelectField
              input={field}
              markReq={item.required}
              id={field.id}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              options={item.options}
              optionLabel={item.optionLabel ? item.optionLabel : 'label'}
              placeholder={item.placeholder}
              item={item}
            />)}
        />
      </div>
    );
  };

  let getRadio = (i, item) => {
    return (
      <div key={i} className=''>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoRadioButtons
              options={item.options}
              input={field}
              id={field.id}
              item={item}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              defVal={item.value}
              markReq={item.required}
            />)}
        />
      </div>
    );
  };

  let getMaskInput = (i, item) => {
    return (
      <div>
        <Controller 
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => {
            return <DoInputMask
                maskPlaceholder={item?.maskPlaceholder }
                required={item.required}
                item={item}
                id={field.id}
                name={field.name}
                label={item.label}
                field={field}
                type={item.type ? item.type : "text"}
                fieldState={fieldState}
                errors={errors}
                defVal={item.value}
                placeholder={item.placeholder ? item.placeholder : null}
                mask={item?.mask}
                setValue={setValue}
                nextFocusedElement={item?.nextFocusedElement}
                focus={focus}
                handleFocus={handleFocus}
            />
          }}
        />
      </div>
    )
  }

  // Payment Deductions
  
  const getPaymentStatus = (value) => {
    let userAmount = 0;
    if(value) userAmount = parseFloat(value);

    if(props?.accounts[0]?.totalAmount  < 0) {
      userAmount += Math.abs(props.accounts[0].totalAmount);
      // setUserPaidAmount(userAmount);
    }

    let currentPaymentCalculation = [];
  
    let initialArray = [];
    if (props.accounts) {
      props.accounts.forEach((item) => {
        if (item.patientTreatment && item.patientTreatment.length > 0) {
          initialArray = [...initialArray, ...item.patientTreatment];
        }
      });
    }

   
    initialArray = initialArray.map(d => {
      if(d?.type === "subtractions" && (!d?.isCompletedPayment || d?.isCompletedPayment === "Pending")) {
        userAmount += d.amount;
        return {...d, isCompletedPayment: "Completed", paymentStatus:"Done", payedAmount: d.amount, unpayedAmount: 0}
      }
      else return d;
    })

    let outputArray = initialArray.map((item, i) => {
      if(item?.isCompletedPayment === "Pending") {
        let amount = parseFloat(item.unpayedAmount ? item.unpayedAmount : item.fee ? item.fee : item.amount)
        if(userAmount > 0 && amount <= userAmount) {
          // previousIndex = i;
          let updatedItem = {...item, isCompletedPayment: "Completed", payedAmount: parseFloat((item?.payedAmount || 0) + amount), unpayedAmount: 0};
          userAmount  = parseFloat(userAmount - amount);
          currentPaymentCalculation.push(updatedItem);
          return updatedItem;
        }
        else if(userAmount > 0 && amount > userAmount) {
          let updatedItem = { ...item, isCompletedPayment: "Pending", payedAmount: parseFloat((item?.payedAmount || 0) + userAmount), unpayedAmount: parseFloat(amount - userAmount)}
          userAmount = 0;
          currentPaymentCalculation.push(updatedItem);
          return updatedItem;
        }
        else{
          let updatedItem = { ...item, isCompletedPayment: "Pending", payedAmount: 0, unpayedAmount: amount,};
          currentPaymentCalculation.push(updatedItem);
          return updatedItem;
        }
      }
      // else if(item?.type === "subtractions" && item?.isCompletedPayment === "Completed" && item?.paymentStatus === "Done") {
      //   currentPaymentCalculation.push(item);
      //   return item;
      // }
      else{
        return item;
      }
    })

    if(userAmount > 0) {
      currentPaymentCalculation.push({...outputArray[0], type:"Deposit", code:"Prepayment", payedAmount: userAmount, unpayedAmount:0, });
    }

    setUserRemainingAmount(userAmount);
    setCalculateArray(currentPaymentCalculation);
    setOutputArray(outputArray);

    return { outputArray };
  };

  // Handle Amount On Focus 
  const handleBlurEvent = (value) =>{
    if(value){
      setUserPaidAmount(value);
      let patientProcedureAmount = getPaymentStatus(value);
      setPaymentStatus(patientProcedureAmount);
    }else{
      setUserPaidAmount(null);
    }
  }

  const  getFormattedCurrencyValue = () => {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: config.currency,
    });
    return dollarUS
  }

  const changeFieldValues = (item, col) => {
    let symbols = { additions: {icon: faPlus, color: "#6d28d9", bgColor: "#c4b5fd"}, subtractions: {icon: faPercentage, color: "#be123c", bgColor:"#fecdd3"}, Deposit: {icon: faDollarSign, color:"#0f766e", bgColor:"#99f6e4"}, refund:{icon: faDollarSign, color:"#0f766e", bgColor:"#99f6e4"}}
    if(col?.field === "providerName" && item.type){
      const findIcon= symbols[item.type];
      return (
        <div className="position-relative overflow-hidden">
          <span>{item[col.field]}</span>
          <div className="position-absolute absolute__position" style={{backgroundColor: findIcon.bgColor}}>
            <div className="w-100 flex justify-content-start align-items-center">
              <FontAwesomeIcon icon={findIcon.icon} color={findIcon.color} className='ms-3 mb-2 icon__size'/>
            </div>
          </div>
        </div>
      )
    }else if(col?.fieldType === "Amount"){
      let dollarUS = getFormattedCurrencyValue()
      let val = dollarUS.format(item[col.field]?item[col.field]:item['amount'])
      return <span>{val}</span>
    }
    return <span>{item[col.field]}</span>
  }

  const getFilteredData = () => {
    let completedPayments = [];
    let outStandingCharges = [];
  
    if (props.accounts) {
      props.accounts.forEach((account) => {
        if (account.patientTreatment && account.patientTreatment.length > 0) {
          account.patientTreatment.forEach((item) => {
            if (item.isCompletedPayment !== "Pending") {
              completedPayments.push(item);
            } else {
              outStandingCharges.push(item);
            }
          });
        }
      });
    }
  
    return { completedPayments, outStandingCharges };
  };

  const setRowClassName = (item, key) => {
    let className = "";
    if(item?.type == "additions") className += "adjustment-color ";
    if(item?.type == "Deposit") className += "deposit-color ";
    if(item?.type == "subtractions") className += "discount-color ";
    if(item?.[key] > 0) return className;
    else return "d-none";
  }

  const TableHeader = (props, tableField) => {
    return (
      <div className='text-center'>
        <span>{tableField.header}</span>
      </div>
    )
  }

  const TableFooter = ({ props }, tableField) => {
    const calculateTotalAmount = () => {
    if(props?.value){
      if(props?.name === "currentPaymentSplits") {
        return props.value.reduce((acc, cur) => {
          if((cur?.payedAmount || cur?.amountCredits) > 0 && cur?.type === "subtractions") return acc - cur.payedAmount;
          if(cur?.payedAmount >0) return acc + cur.payedAmount;
          return acc;
        },0)
      }
      else {
        return props.value.reduce((acc, cur) => {
          if(cur?.unpayedAmount > 0 &&  cur?.type === "subtractions") return acc - cur.unpayedAmount;
          if(cur?.unpayedAmount > 0) return acc + cur.unpayedAmount;
          else return acc;
        }, 0)
      }
    }
    }
    let totalAmount = calculateTotalAmount();
    let dollarUS = getFormattedCurrencyValue()
    let val = dollarUS.format(totalAmount)
    return (
      <>
      {
        totalAmount > 0 && 
        <div className="flex justify-content-end align-items-center">
          <div className="flex gap-4 px-4 py-2 rounded" style={{backgroundColor:"#ffedd5"}}>
            <span className="fw-bold">Total</span>
            <span>{val}</span>
          </div>
        </div>
      } 
      </>
    )
  }

  const showTables = [
    {name: "currentPaymentSplits", header: "Current Payment Splits", rowtype: "payedAmount", tableFields: PaymentCompleteTableFields,}, 
    { name: "outstandingCharges", header: "Outstanding Charges", rowtype: "unpayedAmount", tableFields: outStandingBillsTableFields}
  ]

  const getDataTable = (i, item) => {
    return (
      <div className="d-flex gap-5">
        {
          (userPaidAmount || props?.accounts[0]?.depositAmount > 0 ) && showTables.map( tableField => (
            <div className="w-50">
              <DataTable
              className="border rounded p-2"
              name={tableField.name}
              value={ calculateArray?.length > 0 && calculateArray } 
              scrollable={true} 
              scrollHeight="300px" 
              rowClassName={(item) => setRowClassName(item, tableField.rowtype)}
              footer={(props) => TableFooter(props, tableField)}
              header={(props) => TableHeader(props, tableField)}
            >
              {
                tableField.tableFields.map( (col, index) => (
                  <Column 
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    body={(item) => changeFieldValues(item, col)}
                    style={{ textAlign: 'center', textTransform: 'capitalize' }}
                  />
                )
              )}
              </DataTable>
            </div>
          ))
        }
      </div>
    )
  }



  

  /**
   * 
   * @param {Object} item 
   * @param {Number} index 
   */
  const getDecimal = (index, item) => {
    return <div>
      <Controller
        name={item.name ? item.name : null}
        control={control}
        render={({ field, fieldState }) => (
          <DoDecimalField
            name={item.name ? item.name : null}
            item={item}
            field={field}
            fieldState={fieldState}
            errors={errors}
            handleBlur={handleBlurEvent}
          />)}
      />
    </div>
  }

  const handleRequest = () => {
    const paymentType = getValues("paymentType");
    const choosePayment = getValues("choosepayment");
    if(paymentType === "Paypal") return { data: "Send Request", url: apiCalls.requestPayment}
    else if(paymentType === "Cardknox" && choosePayment === 'Send Request') return {data: "Send Request", url:apiCalls.requestPayment };
    else if(paymentType === "Cardknox" && choosePayment === 'Pay Here') return { data: "Pay", url: apiCalls.cardknoxPayment};
    return {data: "Submit", url:""};
  }

  const handleFocus = (msg) => {
    if(msg === 'expiry') setFocus("expiry");
    if(msg === 'cvv') setFocus("cvv")
    if(msg?.toLowerCase().includes("expired")) setFocus("expiry"), setError("expiry", {message:"Enter valid Expiry"});
    else if(msg?.toLowerCase().includes("card")) setFocus("creditcard"), setError("creditcard", {message: "Invalid Card Number"});
  }

  const modalFooter = () => (
    <div className="d-flex justify-content-end w-100 mt-4">
      <React.Fragment >
        <ReactStrapButton color="primary" className="me-2" onClick={closeFormModal}>Cancel</ReactStrapButton>
        <ReactStrapButton color="primary"
          // disabled={userPaidAmount === '0'}
          type='submit'>{handleRequest()?.data}</ReactStrapButton>
      </React.Fragment>
    </div>
  );


  const submit = (values) => {
    setIsLoading(true);
    let treatmentProcedures = {};
    formFields.map((field) => {
      if (values[field.name]) {
        if (field.type === 'date') {
          values[field.name] = (new Date(values[field.name])).toISOString();
        }
      }
    });
    if (type === 'adjustment') {
      const keys = { "additions": "amountCharges", "subtractions": "amountCredits", "refund": "amountRefund" };
      const key = keys[values.type] ? keys[values.type] : 'amount' ;
      values[key] = values.amount;
    } else if (type === 'payment') {
      values['paymentAmount'] = values.amount;
    }
    const data = getUrlData(type);
    values = { ...values, ...data.addData }
    const payload = { ...values, patient: patientId._id };//{ [data.parentField]: values, patientId };
    if (type === 'payment' && ["Send Request"].includes(handleRequest()?.data)) {
      payload.patientTreatment = outputArray;
      setPayment(payload);
      return;
    }
    if(values?.['paymentType'] === "Cardknox" && values?.['choosepayment'] === "Pay Here"){
      data.url = handleRequest()?.url;
    }
    let body = {
      [data.parentField]: payload,
      patientId,
    }
    if(type == "payment") {
      body.patientTreatment = outputArray;
      body.depositAmount = userRemainingAmount;
    }

    saveDataToServer({ ...body, ...treatmentProcedures }, data);
  }

  const serverRes = (res) => {
    setIsLoading(false);
    if (res?.respCode) {
      showToasterMessage(res?.respMessage, 'success');
      closeFormModal();
    } else {
      showToasterMessage(res?.errorMessage, 'error');
      handleFocus(res?.errorMessage)
    }
  }

  function saveDataToServer(payload, data) {
    fetchMethodRequest(data.method, data.url, payload).then(serverRes);
  }

  function setPayment(data) {
    // setIsLoading(true);
    data.currency = "USD";
    fetchMethodRequest('POST', apiCalls.requestPayment, data).then(res => {
      if (res.override) {
        setPayloadData(res);
      } else {
        serverRes(res);
      }
    })

  }

  const getField = (item, i) => {
    if (!item.show) {
      return;
    }
    return <div className={`${item.customWidth ? item.customWidth : 'col-4 p-2'}`}>{
      item.type === 'date' ? getDate(i, item)
        : item.type === 'dropDown' ? getDropdown(i, item)
          : item.type === 'radio' ? getRadio(i, item)
            : item.type === 'textarea' ? getTextArea(i, item)
              : item.type === 'decimal' ? getDecimal(i, item)
                : item.type === "table" ? getDataTable(i, item)
                  : item.type === "mask" ? getMaskInput(i, item)
                    :item.type === "choosepayment" ? renderChoosePaymentFields()
                      :item.type === "creditcard" ? renderCreditCardForm()
                  : getDefault(i, item)
    }</div>
  }

  function renderForm() {
    return (
      <form onSubmit={handleSubmit(submit)}>
        <div className='d-flex flex-wrap'>
          {modalFooter()}
          {formFields && formFields.map(getField)}
        </div>
      </form>
    );
  }

  const getDilogHeader = () =>{
    let patientName = patientId?.name || '';
    return <div className=''>
      <div className=''>{type} - {patientName}</div> 
    </div>
  }
  function renderCreditCardForm() {
    let part1 = creditcardFormFields.slice(0,2);
    let part2 = creditcardFormFields.slice(2,creditcardFormFields.length);
    return(
      <div className="col-12 d-flex p-2 border rounded">
        <div className="col-6">
            { part1.length > 0 && part1.map(getField)}
        </div>
        <div className="col-6">
          <div className="flex flex-wrap">
              { part2.length > 0 && part2.map(getField)}
          </div>
        </div>
      </div>
    )
  }

  function renderChoosePaymentFields() {
    return (
      <div className="col-12 mt-3 pt-1">
        <div className="col-12 d-flex flex-wrap">
          { choosePaymentFormFields.length > 0 && choosePaymentFormFields.map(getField)}
        </div>
      </div>
    )
  }


  const getConfirmationModal = () => {

    return <Dialog visible={true} className='payment_loading_modal'
      headerStyle={{ display: 'none' }}
      header=""
    // headerStyle={{ backgroundColor: 'white' }}
    // style={{ borderRadius: '20%' }}
    >
      <br />
      <div>Are you sure, do you want to override the existing payment.</div>
      <br />
      <Button outline color="primary" onClick={() => setPayloadData({})} className='deleteModalBtn marginRight'>No</Button>
      <Button outline color="primary" onClick={() => setPayment(payloadData)} className='deleteModalBtn'>Yes</Button>
    </Dialog>
  }

  return <div>
    <Dialog draggable={false} visible={isOpenFormModal} style={{ width: '80vw' }} header={getDilogHeader} headerStyle={{ textTransform: 'capitalize' }} closeOnEscape={false} onHide={closeFormModal}>
      <Loader loader={isLoading} />
      {renderForm()}
    </Dialog>
    {(payloadData?.patient && payloadData?.override && ["Paypal", "Cardknox"].includes(payloadData?.paymentType)) && getConfirmationModal()}
  </div>
    ;

}

export default AccountsFormModal;
