import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import fetchMethodRequest from "../../../config/service";
import apiCalls from "../../../config/apiCalls";
import { Badge, Divider } from "antd";
import Patients from "../../Cruds/Patients";
import DataTable from "../../Cruds/CommonDataTable/DataTable";
import { BadgeColors } from "../../../common/Badge/CommonBadge";
import LineGraph from "../../../common/Graph/LineGraph";


const Dashboard = () => {
    const [data, setData] = useState(null);
    const [upcomingAppointments, setUpcomingAppointments] = useState([]);
    const [clinicWiseGraph, setClinicWiseGraph] = useState([]);
    const [clinics, setClinics] = useState([]);
    const [clinic, setClinic] = useState(() => {
        return localStorage.getItem('ClinicData') || null;
    })

    useEffect(() => {
        fetchMethodRequest("POST", apiCalls.upcomingAppointments, { date: new Date()}).then(res => setUpcomingAppointments(res.upcomingAppointments))
        fetchMethodRequest("POST", apiCalls.clinicWiseGraph, {date: new Date()}).then(res => (setClinicWiseGraph(res.clinicwisegraph), setClinics(res.clinics)));
    },[])

    const cardData = [
        {title: "Clinics", key: "clinics", icon:"hospital", iconColor:"#0284c7", iconBgColor: "#e0f2fe",},
        {title: "Providers", key: "providers", icon:"stethoscope", iconColor:"#e11d48", iconBgColor:"#ffe4e6",},
        {title: "Patients", key: "patients", icon:"hospital-user", iconColor:"#7c3aed", iconBgColor:"#ede9fe",},
        {title: "Appointments", key: "appointments", icon:"calendar-check", iconColor:"#ea580c", iconBgColor:"#ffedd5", ribbonText: "Today"},
    ]



    const AppointmentTableFields =  [
        {
            name: "patientName",
            type: "patientName",
            placeholder: "Patient Name",
            label: "Patient Name",
            width: "120px",
            addFormOrder: 5,
            editFormOrder: 5,
            header: "Patient Name",
            derivedValue: "patientName=undefined",
            capitalizeTableText: false,
            sortable: false,
            isClickable: false,
            filter: false,
            actions: [],
            actionsNumber: [],
            id: "patientName",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinlist: "true",
            isFieldRequired: "true",
            required: true,
            displayOptionsInActions: false,
            globalSearchField: "true",
            controllerId: null,
            textAlign: "left",
            show: true,
            field: "patientName",
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            tDisplay: true,
        },
        {
            name: "handlerName",
            type: "handlerName",
            placeholder: "Operator Name",
            label: "Operator Name",
            width: "120px",
            addFormOrder: 5,
            editFormOrder: 5,
            header: "Operator Name",
            derivedValue: "handlerName=undefined",
            capitalizeTableText: false,
            sortable: false,
            isClickable: false,
            filter: false,
            actions: [],
            actionsNumber: [],
            id: "handlerName",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinlist: "true",
            isFieldRequired: "true",
            required: true,
            displayOptionsInActions: false,
            globalSearchField: "true",
            controllerId: null,
            textAlign: "left",
            show: true,
            field: "handlerName",
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            tDisplay: true,
        },
        {
            name: "providerName",
            type: "providerName",
            placeholder: "Provider Name",
            label: "Provider Name",
            width: "120px",
            addFormOrder: 5,
            editFormOrder: 5,
            header: "Provider Name",
            derivedValue: "providerName=undefined",
            capitalizeTableText: false,
            sortable: false,
            isClickable: false,
            filter: false,
            actions: [],
            actionsNumber: [],
            id: "providerName",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinlist: "true",
            isFieldRequired: "true",
            required: true,
            displayOptionsInActions: false,
            globalSearchField: "true",
            controllerId: null,
            textAlign: "left",
            show: true,
            field: "providerName",
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            tDisplay: true,
        },
        {
            name: "date",
            type: "date",
            placeholder: "Date",
            label: "Date",
            width: "150px",
            addFormOrder: 5,
            editFormOrder: 5,
            header: "Date",
            derivedValue: "date=undefined",
            capitalizeTableText: false,
            sortable: false,
            isClickable: false,
            filter: false,
            actions: [],
            actionsNumber: [],
            id: "date",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinlist: "true",
            isFieldRequired: "true",
            required: true,
            displayOptionsInActions: false,
            globalSearchField: "true",
            controllerId: null,
            textAlign: "left",
            show: true,
            field: "date",
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            tDisplay: true,
            fieldType: "Date",
            customStyle: "Date",
            dateFormat:"MM-DD-YY"
        },
        {
            name: "fromTime",
            type: "fromTime",
            placeholder: "From Time",
            label: "From Time",
            width: "80px",
            addFormOrder: 5,
            editFormOrder: 5,
            header: "From Time",
            derivedValue: "fromTime=undefined",
            capitalizeTableText: false,
            sortable: false,
            isClickable: false,
            filter: false,
            actions: [],
            actionsNumber: [],
            id: "fromTime",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinlist: "true",
            isFieldRequired: "true",
            required: true,
            displayOptionsInActions: false,
            globalSearchField: "true",
            controllerId: null,
            textAlign: "left",
            show: true,
            field: "fromTime",
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            tDisplay: true,
            customStyle: "Date",
            dateFormat: "hh:mm A",
        },
        {
            name: "toTime",
            type: "toTime",
            placeholder: "To Time",
            label: "To Time",
            width: "150px",
            addFormOrder: 5,
            editFormOrder: 5,
            header: "To Time",
            derivedValue: "toTime=undefined",
            capitalizeTableText: false,
            sortable: false,
            isClickable: false,
            filter: false,
            actions: [],
            actionsNumber: [],
            id: "toTime",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinlist: "true",
            isFieldRequired: "true",
            required: true,
            displayOptionsInActions: false,
            globalSearchField: "true",
            controllerId: null,
            textAlign: "left",
            show: true,
            field: "toTime",
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            tDisplay: true,
            customStyle: "Date",
            dateFormat: "hh:mm A"
        },
        {
            name: "procedureCodes",
            type: "procedureCodes",
            placeholder: "Procedure Codes",
            label: "Procedure Codes",
            width: "80px",
            addFormOrder: 5,
            editFormOrder: 5,
            header: "Procedure Codes",
            derivedValue: "procedureCodes=undefined",
            capitalizeTableText: false,
            sortable: false,
            isClickable: false,
            filter: false,
            actions: [],
            actionsNumber: [],
            id: "procedureCodes",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinlist: "true",
            isFieldRequired: "true",
            required: true,
            displayOptionsInActions: false,
            globalSearchField: "true",
            controllerId: null,
            textAlign: "left",
            show: false,
            field: "procedureCodes",
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            tDisplay: true,
        },
        {
            name: "confirmationStatus",
            type: "confirmationStatus",
            placeholder: "Status",
            label: "Status",
            width: "150px",
            addFormOrder: 5,
            editFormOrder: 5,
            header: "Status",
            derivedValue: "confirmationStatus=undefined",
            capitalizeTableText: false,
            sortable: false,
            isClickable: false,
            filter: false,
            actions: [],
            actionsNumber: [],
            id: "confirmationStatus",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinlist: "true",
            isFieldRequired: "true",
            required: true,
            displayOptionsInActions: false,
            globalSearchField: "true",
            controllerId: null,
            textAlign: "left",
            show: true,
            field: "confirmationStatus",
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            tDisplay: true,
            fieldType: "CustomBadge",
            options: [
                { label: 'Unconfirmed', value: 'unconfirmed', color: BadgeColors.PINK }, // Tomato
                { label: 'Confirmed', value: 'Confirmed', color: BadgeColors.BLUE }, //Slate Blue
                { label: 'Arrived', value: 'arrived', color: BadgeColors.LIME }, // Lime Green
                { label: 'Ready', value: 'ready', color: BadgeColors.YELLOW }, // Gold
                { label: 'In Room', value: 'inroom', color: BadgeColors.TEAL }, // Dodger Blue
                { label: 'Checkout', value: 'checkout', color: BadgeColors.PINK }, // Hot Pink
                { label: 'Left Msg', value: 'leftmsg', color: BadgeColors.VIOLET }, // Blue Violet
                { label: 'Bad Num', value: 'badnum', color: BadgeColors.RED }, // Brown
                { label: 'E-mailed', value: 'emailed', color: BadgeColors.GREEN }, // Light Sea Green
                { label: 'Texted', value: 'texted', color: BadgeColors.ORANGE }, // Orange
                { label: 'E-Confirm Sent', value: 'econfirmsent', color: BadgeColors.ZINC }, // Light Slate Gray
                { label: 'E-Confirm Call', value: 'econfirmcall', color: BadgeColors.VIOLET }, // Pale Violet Red
                { label: 'E-Confirm Fault', value: 'econfirmfault', color: BadgeColors.GREEN }, // Sea Green
                { label: 'Web Sched', value: 'websched',color: BadgeColors.TEAL }, // Slate Blue
                { label: 'Out The Door', value: 'outthedoor',color: BadgeColors.BLUE } // Orange Red
              ]
        },
    ]

      const getLocalStorageObservable = () => {
             const storedClinic = localStorage.getItem('ClinicData');
            setClinic(storedClinic);
      }

      useEffect(() =>{
          window.addEventListener("PatientBar", getLocalStorageObservable);
          return () => window.removeEventListener("PatientBar", getLocalStorageObservable)
      },[])

      useEffect(() => {
        fetchMethodRequest("POST", apiCalls.dashboard, {date : new Date()}).then( res => {
            setData(res?.counts);
          })
      }, [clinic])


      return (
				<Container className="px-4 pt-4">
					<Row className="gx-4 gy-4">
						{cardData.map((item, i) => (
							<Col xl={3} md={6} xs={12}>
								<Badge.Ribbon
									color={item.iconColor}
									className="z-1"
									text={
										item?.ribbonText ? (
											<div className="flex gap-2 justify-content-center">
												<span className="fw-bold">{item.ribbonText}</span>
												<span>
													{" "}
													<FontAwesomeIcon
														icon={["fa", "angle-double-right"]}
														color={"#ffff"}
														size={24}
													/>{" "}
												</span>
											</div>
										) : (
											<TrendingUpIcon color="white" />
										)
									}
									style={{ zIndex: 1000 }}
								>
									<DashboardCard
										{...item}
										key={i}
										count={data?.[item.key]}
									></DashboardCard>
								</Badge.Ribbon>
							</Col>
						))}

                        <Col md={12} xl={5}>
							<div className="rounded bg-white shadow" style={{ height: "600px" }}>
                                <div
                                            className="d-flex align-items-center py-3 px-1"
                                            style={{
                                                backgroundColor: "#fafaf9",
                                                color: "#44403c",
                                                borderBottom: ".5px solid #d6d3d1",
                                                position: "sticky",
                                                top: 0,
                                                zIndex: 10,
                                            }}
                                        >
                                            <span className="sticky-indicator gap-0"></span>
                                            <span
                                                className="fw-bold px-4 flex gap-4 align-items-center"
                                                style={{
                                                    fontFamily: `"Inter", sans-serif`,
                                                    fontSize: "1rem",
                                                    letterSpacing: ".5px",
                                                }}
                                            >
                                                <span>
                                                    <FontAwesomeIcon
                                                        icon={["fa", "calendar-alt"]}
                                                        size={24}
                                                    />
                                                </span>
                                                Weekly Appointments
                                            </span>
                                </div>
                                <LineGraph graphData={clinicWiseGraph} clinics={clinics} />
							</div>
						</Col>
						<Col md={12} xl={7} style={{ zIndex: 0 }} >
							<div
								className="bg-white rounded"
								style={{ height: "600px" }}
							>
								<div className="upcoming__appointments fw-bold position-relative">
									<div
										className="d-flex align-items-center py-3 px-1"
										style={{
                                            backgroundColor: "fafaf9",
											color: "44403c",
											borderBottom: ".5px solid #d6d3d1",
											position: "sticky",
											top: 0,
											zIndex: 10,
										}}
									>
                                        <span className="sticky-indicator gap-0"></span>
										<span
											className="fw-bold px-4 flex gap-4 align-items-center"
											style={{
												fontFamily: `"Inter", sans-serif`,
												fontSize: "1rem",
												letterSpacing: ".5px",
											}}
										>
											<span>
												<FontAwesomeIcon
													icon={["fa", "calendar-alt"]}
													size={24}
												/>
											</span>
											Upcoming Appointments
										</span>
									</div>

									<div className="" style={{ width: "100%"}}>
										<div className="">
											<DataTable
												getTableFields={() => AppointmentTableFields}
												formFields={() => AppointmentTableFields}
												footerClassName="footer__bg_color"
												results={upcomingAppointments}
												tableFields={AppointmentTableFields}
												type={"Upcoming Appointments"}
                                                resultsFromParent
												displayViewOfForm="screen"
												disableRefresh={true}
												showFilterOptions={true}
												disableHeader={true}
												isDisablePaginator={true}
												disableClearFilter={true}
												disableSaveFilter={true}
												disableActionDropdown={true}
												disableTableScroll={true}
                                                tableScrollHeight={"530px"}
												className="dashboard__table"
												rowClassName="inter__fontfamily px-5 py-5"
												columnHeaderClassName="dashboard__column__header"
											/>
										</div>
									</div>
								</div>
								{/* <Patients /> */}
							</div>
						</Col>
						
					</Row>
				</Container>
			);
	
};


const DashboardCard = ({ children, trend = "up", title, count, icon, iconColor, iconBgColor }) => {
    return (
		<div className="rounded flex align-items-end gap-5 border bg-white py-3 px-4 shadow-sm card__container" style={{'--pseudo-color': iconColor, fontFamily: `"Inter", sans-serif`}}>
            {/**@LeftContainer */}
            <div className="w-75">
                <div
                    className="d-flex align-items-center justify-content-center rounded-circle"
                    style={{ width: "48px", height: "48px", backgroundColor: iconBgColor, fontSize:"1rem" }}
                >
                    <FontAwesomeIcon icon={["fa",icon]} color={iconColor} size={24}/>
                </div>

                <div style={{fontSize:"1rem", fontWeight:"500", color:"#64748B"}} className="pt-3 pb-2">{title}</div>

            </div>
            {/**@RightContainer */}
            <div>
                <h4 className="pb-3" style={{fontSize:"1.5rem", color:iconColor, fontWeight:"700", fontStyle:"italic"}}>{count}</h4>
            </div>
		</div>
	);
}
// EFF2F7


export default Dashboard;
