import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import { MultiSelect } from 'primereact/multiselect';
import moment from 'moment';
import CustomTimePicker from '../../Form/Fields/CustomTimePicker';
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;
import dayjs from 'dayjs';
import DoMultiSelectField from '../../Form/Fields/DoMultiSelectField';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CreateSlotModal = ({ eventDate, startTime, endTime, disabled, errors, rowData,  setValue, getValues, control }) => {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, 'days'));
  const [operativeOptions, setOperativeOptions] = useState([]);
  const [placement, SetPlacement] = useState('bottomRight');
  const [size, setSize] = useState('large');
  useEffect(() => {

    window.addEventListener('PatientBar', getOperativesData);

    return () => {
      window.removeEventListener('PatientBar', getOperativesData);
    };
  });

  useEffect(() => {
    getOperativesData();
    if(Array.isArray(rowData) && rowData.length > 0 && setValue) {
      let arr= rowData.map(obj=>obj?.handlers?._id).filter((id,index,self) => index === self.findIndex(d => d == id))
      setTimeout(() => {
        setValue('handler', arr)
      }, 200);
      }
  }, []);

  const onFormSubmit = data => {
    onSubmit(data);  // Invoke the passed onSubmit function with form data
  };
  
  const markRequired = () => (
    <FontAwesomeIcon
      color="red"
      icon={faAsterisk}
      style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
    />
  );

  const getOperativesData = () => {
    const storedClinicDataString = localStorage.getItem('ClinicData');
    if (!storedClinicDataString) {
      return;
    }
    const storedClinicData = JSON.parse(storedClinicDataString);
    const requestUrl = `${apiCalls.clinics}/${storedClinicData._id}`;

    // Clear the DoMultiSelectField before fetching new data
    // setValue('handler', []);

    fetchMethodRequest('GET', requestUrl)
      .then(async (response) => {
        if (response && response.chairs) {
          let userData = response.chairs;
          let operativeData = userData.filter(item => item.role === "Operative");
          // setOperativeOptions(userData);
          setOperativeOptions(userData.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
            .map((obj) => { return { label: obj.name, value: obj._id, data: obj } }));
        }
      })
      .catch(error => {
        console.error('Error fetching Operatives:', error);
      });
  };
  
  const rangePresets = [
    {
      label: 'Tomorrow',
      value: [dayjs().add(+1, 'd'), dayjs()],
    },
    {
      label: 'Next 7 Days',
      value: [dayjs().add(+7, 'd'), dayjs()],
    },
    {
      label: 'Next 14 Days',
      value: [dayjs().add(+14, 'd'), dayjs()],
    },
    {
      label: 'Next 30 Days',
      value: [dayjs().add(+30, 'd'), dayjs()],
    },
    {
      label: 'Next 90 Days',
      value: [dayjs().add(+90, 'd'), dayjs()],
    },
    {
      label: 'Next 180 Days',
      value: [dayjs().add(+180, 'd'), dayjs()],
    },
  ];

  /**
   * 
   * @param {String} time 
   * @returns Number
   */
  const convertIntoMin = (time) => {
    const regex = /^\d{2}:\d{2}$/;
    if (time && regex.test(time)) {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    }
    return 0;
  }

  /**
   * 
   * @param {String} startTime 
   * @param {String} endTime 
   * @returns 
   */
  const validateTimeRange = (startTime, endTime) => {
    startTime = convertIntoMin(startTime);
    endTime = convertIntoMin(endTime);
    if (!startTime || !endTime || startTime < endTime) {
      return true;
    }
    return false;
  }
 

  return (
    <div className='AddSlotGroup'>
      <div class="multiselectOperative">
        <div class="form-group">
          <label htmlFor="handler" class="form-label" style={{ fontSize: '18px' }}>Operatory
          {markRequired()}
          </label>
        </div>
        <div class="form-component">
          <Controller
            name="handler"
            control={control}
            rules={{ required: 'Operatory is required' }}
            render={({ field, fieldState }) => (
              //   <>
              //   <MultiSelect
              //     value={field.value}
              //     options={operativeOptions}
              //     onChange={(e) => field.onChange(e.value)}
              //     optionLabel="name"
              //     filter
              //     placeholder="Select Operatories"
              //     maxSelectedLabels={3}
              //     className="w-full"
              //   />
              //   {/* {fieldState.error && <p style={{ color: 'red' }}>{fieldState.error.message}</p>} */}
              // </>
              <>
                <DoMultiSelectField
                  input={field}
                  id={field.id}
                  item={{ disableLabel: true, isDisable: disabled }}
                  name={field.name}
                  field={field}
                  fieldState={fieldState}
                  errors={errors}
                  // label={'Operatory'}
                  options={operativeOptions}
                  optionLabel={'label'}
                  placeholder={'Select Operatories'}
                />
              </>
            )}
          />
        </div>
      </div>
      <div className='calendar-form-group-date' >
        <label htmlFor="eventDate">Date 
        {markRequired()}
        </label>
        <div className="schedule-date">
            <Controller
              name="dateRange"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <RangePicker
                    onChange={(dates, dateStrings) => {
                      const formattedDates = dateStrings.map(date => dayjs(date).format('YYYY-MM-DD'));
                      field.onChange(formattedDates);
                    }}
                    style={{height: "50px", width: "100%"}}
                    format="MM-DD-YYYY"
                    placement={placement}
                    size={size}
                    onCalendarChange={(dates, dateStrings, info) => console.log('Calendar Changed:', dateStrings)}
                    presets={[
                      {
                        label: <span aria-label="Current Time to End of Day">Today</span>,
                        value: () => [dayjs(), dayjs().endOf('day')],
                      },
                      ...rangePresets,
                    ]}
                  />
                  {errors?.["dateRange"] && <p style={{ color: 'red', marginTop: '8px', fontSize: '0.775em' }}>{errors["dateRange"]?.message}</p>}
                </>
              )}
            />
        </div>
      </div>

      <div className="calendar-form-group-row time-picker">
        <div className="calendar-form-group schedule-group-time">
          <label htmlFor="startTime">From Time 
          {markRequired()}
          </label>
          <Controller
            name="startTime"
            control={control}
            rules={{ required: 'From Time is required' }}
            render={({ field, fieldState }) => (
              <>
                <CustomTimePicker id="startTime" {...field} timeOnly hourFormat="12" clearIcon
                  placeholder={"Start Time"}
                  onChange={(startTimeValue) => {
                    field.onChange(startTimeValue);
                  }}
                />
                {fieldState.error && <small style={{ color: 'red' }}>{fieldState.error.message}</small>}
              </>
            )}
          />
        </div>

        <div className="calendar-form-group schedule-group-time">
          <label htmlFor="endTime">To Time 
          {markRequired()}
          </label>
          <Controller
            name="endTime"
            control={control}
            rules={{
              required: 'To Time is required',
              validate: value => validateTimeRange(getValues && getValues('startTime'), value) || 'To time must be greater than the from time.',
            }}
            render={({ field, fieldState }) => (
              <>
                <CustomTimePicker id="endTime" {...field} timeOnly hourFormat="12" clearIcon placeholder={"End Time"} />
                {fieldState.error && <small style={{ color: 'red' }}>{fieldState.error.message}</small>}
              </>
            )}
          />
        </div>
      </div>


    </div>
  );
};
export default CreateSlotModal;
