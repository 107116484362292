import Proptypes from "prop-types";

export const BadgeColors = {
	GREEN: "green",
	PINK: "pink",
	ORANGE: "orange",
	VIOLET: "violet",
	PURPLE: "purple",
	YELLOW: "yellow",
	LIME: "lime",
	TEAL: "teal",
	CYAN: "cyan",
	BLUE: "blue",
	INDIGO: "indigo",
	ROSE: "rose",
	RED: "red",
	ZINC: "zinc",
};

const badgeColorCombinations = {
	[BadgeColors.GREEN]: { bgColor: "#dcfce7", color: "#16a34a" },
	[BadgeColors.PINK]: { bgColor: "#fce7f3", color: "#db2777" },
	[BadgeColors.ORANGE]: { bgColor: "#ffedd5", color: "#ea580c" },
	[BadgeColors.VIOLET]: { bgColor: "#ede9fe", color: "#7c3aed" },
	[BadgeColors.PURPLE]: { bgColor: "#f3e8ff", color: "#9333ea" },
	[BadgeColors.YELLOW]: { bgColor: "#fef9c3", color: "#ca8a04" },
	[BadgeColors.LIME]: { bgColor: "#ecfccb", color: "#65a30d" },
	[BadgeColors.TEAL]: { bgColor: "#ccfbf1", color: "#0d9488" },
	[BadgeColors.CYAN]: { bgColor: "#cffafe", color: "#0891b2" },
	[BadgeColors.BLUE]: { bgColor: "#dbeafe", color: "#2563eb" },
	[BadgeColors.INDIGO]: { bgColor: "#e0e7ff", color: "#4f46e5" },
	[BadgeColors.ROSE]: { bgColor: "#ffe4e6", color: "#e11d48" },
	[BadgeColors.RED]: { bgColor: "#fee2e2", color: "#dc2626" },
	[BadgeColors.ZINC]: { bgColor: "#f0fdf4", color: "#3f3f46" },
};

const defaultProps = {
	customBadge: false,
	options: [],
	value: "",
};

const CommonBadge = ({ children, field, options, value, customBadge, color }) => {
	let badgeColor = badgeColorCombinations[BadgeColors.GREEN];
	if(customBadge && color) badgeColor = badgeColorCombinations[color]
	else if (options.length > 0) {
		let findOption = options.find((option) => option.value === value);
		if (findOption) badgeColor = badgeColorCombinations[findOption.color];
	}
	return (
		<div
			className={`px-2 py-1`}
			style={{
				display: "inline",
				backgroundColor: badgeColor.bgColor,
				color: badgeColor.color,
				borderRadius: "3px",
				fontSize: "1rdbeafeem",
				fontFamily: `"Inter", sans-serif`,
				fontWeight: "700",
				letterSpacing: "0.6px",
				fontSize: ".8rem",
				textTransform: "capitalize"
			}}
		>
			{children}
		</div>
	);
};

CommonBadge.defaultProps = defaultProps;
CommonBadge.propTypes = {
	children: Proptypes.node.isRequired,
	field: Proptypes.object,
	options: Proptypes.array,
	value: Proptypes.string,
	customBadge: Proptypes.bool,
	color: Proptypes.string,
};

export default CommonBadge;
