import pluralize from "pluralize";
import config from "../config/config";

export const camelizedString = (str) => {
    str = str.charAt(0).toLowerCase() + str.slice(1);
    return str.replace(/\W+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
}

const plurarizeStr = str => {
  return pluralize.plural(str);
}

const jsUcfirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const formatPropType = str => {
  return jsUcfirst(plurarizeStr(camelizedString(str)))
}

const getFormattedCurrencyValue = (val) => {
  let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: config.currency,
  });
  return dollarUS.format(val)
}

const convertFilterCriteriaToDataTableInLineData = (filterCriteria, tableFields) => {
  let event = {};
  const types = {
    sw: "startsWith",
    ew: "endsWith",
    eq: "equals",
    ne: "notEquals",
    in: "in",
    lt: "lt",
    lte: "lte",
    gt: "gt",
    gte: "gte",
    between: "between",
    number: "",
    date: "dateIs",
  };
  const criteria = filterCriteria?.criteria ? filterCriteria?.criteria : [];

  tableFields = tableFields ? tableFields : [];
  tableFields.forEach(item => {
    let element = criteria.find(ele => ele?.key === item?.field);
    element = element ? element : { key: item?.field, type: item.type };
    const matchMode = types[element.type] ? types[element.type] : 'contains';
    const value = element.value ? element.value : null;
    event[element.key] = {
      operator: "and",
      constraints: [{ value, matchMode }]
    }

  })

  return event;
}

export { formatPropType, getFormattedCurrencyValue, convertFilterCriteriaToDataTableInLineData };