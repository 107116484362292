
import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import apiCalls from "../../config/apiCalls";
import { Dialog } from 'primereact/dialog';
import fetchMethodRequest from "../../config/service";
import showToasterMessage from "../UI/ToasterMessage/toasterMessage";

import DoAutoCompleteField from "./Fields/DoAutoCompleteField";
import DoMultiSelectField from "./Fields/DoMultiSelectField";
import config from "../../config/config";
import FormsFunctions from "../Cruds/CommonFunctions/FormsFunctions";



export default function FormsGeneratingForm(props) {

    const [schema, setSchema] = useState('');

    let { handleSubmit, register, reset, setValue, getValues, formState: { errors }, control, } = useForm({ resolver: yupResolver(schema), });
    const [hospitalId, setHospitalId] = useState({});
    const [formsOptions, setFormsOptions] = useState([]);


    useEffect(() => {
        getFormsData();
        if(localStorage.HospitalData){
            setHospitalId(JSON.parse(localStorage.getItem('HospitalData')));
        }
        setSchema(yup.object().shape({
            // hospitalId: yup.object().required('Hospital ID is required'), //validation for Hospital ID
            patientId: yup.object().required('Patient ID is a required'),//Validation for Patient ID
            forms: yup.array().required('Forms is a required'),
        }))
    }, []);

    const getFormsData = async () => {
        let arr = (await fetchMethodRequest("GET", apiCalls.templates))[apiCalls.templates]
        setFormsOptions(arr);
    }


    const submit = async (values) => {
        if (values) {
            let userBody = values;
            if (userBody.forms && userBody.forms.length) {
                userBody.forms = await userBody.forms.map((obj) => {
                    let data = {}
                    data.formName = obj._id;
                    data.templateData = FormsFunctions.templateTextConverter(obj).templateText;
                    data.questions = [];
                    data.patientSignature = "";
                    data.doctorSignature = "";
                    data.patientId = userBody.patientId;
                    data.hospitalId = hospitalId;
                    data.status = obj.filledByFrontDesk ? undefined :'Form sent' ;
                    return data
                })
            }

            fetchMethodRequest('POST', apiCalls.multipleForms, userBody).then((res) => {
                if (res && res.respMessage) {
                    showToasterMessage(res.respMessage, 'success')
                    reset();
                    props.closeFormModal();
                    props.getDataFromServer();
                } else {
                    showToasterMessage(res.errorMessage, 'error')
                }
            })
        }
    }

    let getButtonToolbar = () => {

        return (
            <div className='d-flex mt-5'>
                <div className='col-12 px-0'>
                    <span className='float-end'>
                        <Button color='primary' type="button" onClick={props.closeFormModal} > Back </Button>
                        <Button color='primary'  type="submit"> Generate Link </Button>
                    </span>
                </div>
            </div>
        )
    }

    let getAutoComplete = (i, item) => {
        let itemTemplateFunction;

        if (item.populateValue && item.populateField) {
            itemTemplateFunction = (option) => {
                const value = option[item.populateValue];
                const label = option[item.populateField];
                return (
                    <div className="autocomplete-item">
                        <div>{`${value} (Name : ${label})`}</div>
                    </div>
                );
            };
        }
        return (
            <div className="text-left">
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoAutoCompleteField
                            input={field}
                            id={field.id}
                            name={field.name}
                            field={field}
                            item={item}
                            filterField={item.filterField}
                            filterValue={item.filterValue}
                            filterType={item.isNotEq}
                            multiple={item.isMultiple}
                            fieldState={fieldState}
                            errors={errors}
                            screen={props.type}
                            searchApi={item.searchApi}
                            itemTemplate={itemTemplateFunction}
                            searchField={item.searchField}
                            allow={props.allowDuplicates}
                            placeholder={item.placeholder}
                            label={item.label}
                            setValue={setValue}
                            formType={"add"}
                        />)}
                />
            </div>
        )
    }

    let getDropdownMultiselect = (i, item) => {
        return (
            <div>
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoMultiSelectField
                            input={field}
                            id={field.id}
                            item={item}
                            name={field.name}
                            field={field}
                            fieldState={fieldState}
                            errors={errors}
                            label={item.label}
                            options={item.options}
                            optionLabel={item.optionLabel}
                            placeholder={item.placeholder}
                        />)}
                />
            </div>
        )
    }


    return <div style={{ display: 'flex' }}>
        <div id="template" style={{ fontSize: '25px', padding: '30px' }}></div>
        <Dialog visible={props.isOpenFormModal} style={{ width: '30vw' }} header={'Forms'} onHide={props.closeFormModal} draggable={false} >

            <form onSubmit={handleSubmit(submit)} autoComplete={'off'} className="form-contai">

                <div>
                    {getAutoComplete(0, {
                        name: "patientId",
                        placeholder: "Patient ID",
                        label: "Patient ID",
                        id: "patientId",
                        isFieldRequired: "false",
                        required: true,
                        searchField: "fullName",
                        clinicFilter:true,
                        controllerName: "patients",
                        searchApi: "patients",
                        isMultiple: false,
                        textAlign: "Center",
                        show: true,
                        field: "patientId",
                        showOrHideFields: [],
                        mobile: true,
                        displayInSettings: true,
                    })}
                </div>

                <div>
                    {formsOptions && getDropdownMultiselect(1, {
                        name: "forms",
                        placeholder: "Select Forms",
                        label: "Select Forms",
                        capitalizeTableText: false,
                        id: "forms",
                        displayinlist: "true",
                        isFieldRequired: "false",
                        required: true,
                        displayOptionsInActions: false,
                        searchField: "name",
                        searchApi: apiCalls.templates,
                        isMultiple: true,
                        textAlign: "Center",
                        show: true,
                        options: formsOptions ? formsOptions : [],
                        optionLabel: 'name',
                        placeholder: 'Select the Forms',
                        field: "forms",
                        mobile: true,
                    })}
                </div>

                {getButtonToolbar()}
            </form>


        </Dialog>

    </div >

}