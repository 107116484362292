import React, { useRef, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import config from '../../../config/config';
// File Upload
import FileUpload from '../../Form/components/FileUpload';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
// config file
const BulkUploadModal = (props) => {
    const [clickCount, setClickCount] = useState(0);
    const fileUploadRef = useRef();
    const clinicId = localStorage.ClinicData ? JSON.parse(localStorage.ClinicData) : {};
    const defaultData = clinicId?._id ? { clinicId: clinicId._id } : null;
    let defaultCriteria = props.defaultCriteria ? props.filterCriteria : '';
    /**@FileName Extraction */
    let filename = props.sampleFilePath.split("/").slice(-1)[0]

    const uploadToServer = () => {
        if (clickCount == 0) {
            fileUploadRef.current.UploadFileToServer();
        }
    }

    const handleClickCount = () => {
        setClickCount(0);
    }


    const { t } = props;

    const handleDownloadRequest = async () => {
        const userBody = {
            type: props.type,  // Assuming the body needs to specify the type (Users or Patients)
            clinicId: defaultData.clinicId, // Including clinicId if needed
        };
        const method = "POST";
        const apiUrl = `uploads/generateExcel`;
    
        fetchMethodRequest(method, apiUrl, userBody)
            .then(async (response) => {
                if (response && response.respCode) {
                    // Check if the file path is available
                    if (response.filePath) {
                        // Fetch the file content from the filePath
                        const fileUrl = `${config.previousImageUrl}${response.filePath}`; // Modify as per your server's URL structure
                        fetch(fileUrl)
                            .then(res => res.blob())
                            .then(blob => {
                                // Create a URL for the blob
                                const blobUrl = window.URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = blobUrl;
                                a.download = response.filePath.split('/').pop(); // Sets the filename from the filePath
                                document.body.appendChild(a);
                                a.click();
                                a.remove();  // Clean up
                                window.URL.revokeObjectURL(blobUrl);
                            })
                            .catch(err => {
                                console.error("Error downloading the file:", err);
                                showToasterMessage("Error downloading the file.", "error");
                            });
                    }
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, "error");
                }
            })
            .catch((err) => {
                console.error("Error during the fetch operation:", err);
            });
    };
    
    const downloadButtonHandler = (event) => {
        event.preventDefault();
        if (props.type === 'Users' || props.type === 'Patients') {
            handleDownloadRequest();
        } else {
            const link = document.createElement('a');
            link.href = props.sampleFilePath;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };    

    return (
        <Modal isOpen={props.openBulkUploadModal}
            className={`modal-dialog-centered modal-dialog--primary modal-dialog--header `}>

            <ModalHeader className="modal__header">
                <button className="lnr lnr-cross modal__close-btn" type="button"
                    onClick={() => props.closeBulkModal() && props.reload(defaultCriteria)} />
                <p className="bold-text  modal__title"> {'Bulk Upload'} </p>
            </ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className='col-5'>
                        {'Sample Document'}
                    </div>
                    <div className='col-7'>
                        <a className='btn btn-primary outline text-white' onClick={downloadButtonHandler}>Download</a>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-5'>
                        {'Please Attach File'}
                    </div>
                    <div className='col-7 bulkuploadbutton'>
                        <FileUpload type="bulkUpload"
                            defaultData={defaultData}
                            ref={fileUploadRef}
                            url={props.type}
                            close={props.closeBulkModal}
                            reload={props.reload}
                            criteria={defaultCriteria}
                        />
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-sm-12 text-center'>
                        <Button color='primary' outline
                            onClick={() => props.closeBulkModal() && props.reload(defaultCriteria)}>{'Close'}</Button>
                        <Button color='primary'
                            onClick={uploadToServer}>{'Upload'} - {props.type}</Button>
                    </div>
                </div>
            </ModalBody>

        </Modal >
    );

}

export default BulkUploadModal;