import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import "./InsuranceClaim.scss";

const EditableStatusTemplate = ({ rowData, handleSave }) => {
    const [editMode, setEditMode] = useState(false);
    const [status, setStatus] = useState(rowData.item.status);
    const statusOptions = [
        { label: "Accept", value: "Accept" },
        { label: "Reject", value: "Reject" }
    ];



    const handleDropdownChange = (e) => {
        setStatus(e.value);
        handleSave(rowData, e.value); 
        setEditMode(false); 
    };

    const saveChanges = () => {
        
    };
    return (
        <div className="insurance-claim-editable">
            {editMode ? (
                <div className="insurance-claim-table-dropdown" style={{ display: 'flex', alignItems: 'center' }}>
                    <Dropdown 
                        value={status} 
                        options={statusOptions} 
                        onChange={handleDropdownChange} 
                        placeholder="Select a status"
                        style={{ width: '150px', marginRight: '5px' }}
                    />
                </div>
            ) : (
                <div>
                    {rowData.item.status || "Sent"}
                    <FontAwesomeIcon icon={faEdit} onClick={() => setEditMode(true)} style={{ marginLeft: '5px', cursor: 'pointer' }} />
                </div>
            )}
        </div>
    );
};

export default EditableStatusTemplate;
