import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Dashboard from './components/Dashboard';
const dashboard = (props,{ t }) => (
  <Container>
    <Dashboard {...props} />
  </Container>
);

dashboard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(dashboard);
