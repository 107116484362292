import React from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import formatDate from '../../UI/FormatDate/formatDate';
const   CustomTimePicker = ({label , field, value, onChange, fieldState , errors, format = 'h:mm A', use12Hours = true , item , screen , markReq ,disabled = false, clearIcon, placeholder }) => {
  const handleTimeChange = (value) => { 
    const formattedTime = value ? value.format('HH:mm') : '';
    onChange(formattedTime);
  };
   item ? item : {}


   let markRequired = () => {
    return (
      <FontAwesomeIcon
        color='red'
        icon={faAsterisk}
        style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
      />
    );
  };

  return (
    <>   

    {screen === "appointments" && <div>
     <div className="doautocomplete-label">
                {!item.disableLabel && <label style={{fontWeight:"lighter"}} htmlFor={field.name}>
                    {label}
                    {markReq && markReq === true && markRequired()}
                </label>}
            </div>
    </div>} 
    <TimePicker
      className={clearIcon && 'p-input'}
       showSecond={false}
       //defaultValue={value ? moment(value, 'HH:mm') : null}
       value={value ? moment(value, 'HH:mm') : null}
      onChange={handleTimeChange}
      format={'h:mm A'}
      use12Hours={use12Hours}
      inputReadOnly
      disabled={disabled}
      minuteStep={15}
      placeholder={placeholder}
      clearIcon={clearIcon && ClearIcon()}
      />
    {screen === "appointments" &&<small className="text-danger ">{fieldState.invalid ? errors[item.name]?.message : ''}</small>}
  </>
  );
};


const ClearIcon = () => {
  return (
    <div className="timepicker__clear__icon" title="Clear">
      <FontAwesomeIcon
        icon={["fa", "times"]}
        color={"#ff4861"}
        size={24}
      />
    </div>
  )
}

export default CustomTimePicker;