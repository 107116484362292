import React, { useEffect, useState, useRef, forwardRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import { Col, Container, Row } from "reactstrap";
import { Divider } from "antd";
import DataTable from "../../CommonDataTable/DataTable";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BadgeColors } from "../../../../common/Badge/CommonBadge";

// config file

const PaidHistories = forwardRef((props, ref) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [paidHistories, setPaidHistories] = useState([]);
  const [refetch, setRefetch] = useState(false);

  let dataTableRef = useRef(null);
  const params = useParams();

  const getPaidHistories = () => {
    setSelectedRow(props.allUsersData.find(data =>data?._id == props?.selectedId))
    fetchMethodRequest("GET", apiCalls.paidHistories+`?filter=${JSON.stringify({criteria: [{key:"dueId", value: props.selectedId, type: "eq"}]})}`).then(res => {
      setPaidHistories(res.paidhistories.map((data, index) => ({...data, Sno: index+1})))
      console.log(res.paidhistories.length)
    })
  }
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions(
      "Dues And Subscriptions"
    );
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }

    getPaidHistories();

    if(ref.current) ref.current.refetch = () => { setRefetch(prev => !prev), getPaidHistories() }
    
  }, []);

  useEffect(() => {
    getPaidHistories();
  },[refetch, setRefetch])



  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "item",
        fieldName: "item",
        type: "text",
        placeholder: "Task/Item",
        value: "",
        label: "Task/Item",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Task/Item",
        derivedValue: "item=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "item",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "item",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "description",
        fieldName: "description",
        type: "text",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "contactName",
        fieldName: "contactName",
        type: "text",
        placeholder: "Contact Name/Company",
        value: "",
        label: "Contact Name/Company",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        stringType: null,
        header: "Contact Name/Company",
        derivedValue: "contactName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "contactName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "contactName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Address",
        fieldName: "Address",
        type: "text",
        placeholder: "Address",
        value: "",
        label: "Address",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        stringType: null,
        header: "Address",
        derivedValue: "Address=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "Address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "Address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Email",
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone",
        fieldName: "phone",
        type: "text",
        placeholder: "Phone",
        value: "",
        label: "Phone",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        stringType: null,
        header: "Phone",
        derivedValue: "phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "phone",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "dueDate",
        fieldName: "dueDate",
        type: "date",
        placeholder: "Due Date",
        value: "",
        label: "Due Date",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        stringType: null,
        header: "Due Date",
        derivedValue: "dueDate=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: false,
        id: "dueDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "MM-DD-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "dueDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        customStyle: "Date",
      },
      {
        name: "frequency",
        fieldName: "frequency",
        type: "dropDown",
        placeholder: "Frequency",
        value: "",
        label: "Frequency",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        stringType: null,
        header: "Frequency",
        derivedValue: "frequency=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "frequency",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "DAILY",
            value: "daily",
            color: BadgeColors.TEAL,
            dependentField: "",
            id: "dpc",
          },
          {
            label: "WEEKLY",
            value: "weekly",
            color: BadgeColors.LIME,
            dependentField: "",
            id: "dpc",
          },
          {
            label: "MONTHLY",
            value: "monthly",
            color: BadgeColors.VIOLET,
            dependentField: "",
            id: "dpc",
          },
          {
            label: "HALF YEARLY",
            value: "half yearly",
            color: BadgeColors.ROSE,
            dependentField: "",
            id: "dpc",
          },
          {
            label: "YEARLY",
            value: "yearly",
            color: BadgeColors.ORANGE,
            dependentField: "",
            id: "dpc",
          },
          {
            label: "MULTIPLE YEARS",
            value: "multiple years",
            color: BadgeColors.BLUE,
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "DAILY",
            value: "daily",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "WEEKLY",
            value: "weekly",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "MONTHLY",
            value: "monthly",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "HALF YEARLY",
            value: "half yearly",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "YEARLY",
            value: "yearly",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "MULTIPLE YEARS",
            value: "multiple years",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "frequency",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        customStyle: "commonBadge"

      },
      {
        name: "amount",
        fieldName: "amount",
        type: "number",
        placeholder: "Amount",
        value: "",
        label: "Amount",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        stringType: null,
        header: "Amount",
        derivedValue: "amount=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "amount",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "amount",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "docs",
        fieldName: "docs",
        type: "fileUpload",
        placeholder: "Pic/Scan Doc",
        value: "",
        label: "Pic/Scan Doc",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        stringType: null,
        header: "Pic/Scan Doc",
        derivedValue: "docs=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "docs",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "other",
        maxFileSize: 1,
        isMultipleRequired: true,
        imagePath: "Paid History",
        textAlign: "center",
        show: false,
        field: "docs",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "ownerName",
        fieldName: "ownerName",
        type: "text",
        placeholder: "Owner/Assigned",
        value: "",
        label: "Owner/Assigned",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        stringType: null,
        header: "Owner/Assigned",
        derivedValue: "ownerName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "ownerName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "ownerName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        stringType: null,
        header: "Status",
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "Pending",
            value: "pending",
            color: BadgeColors.ORANGE,
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Paid",
            value: "paid",
            color: BadgeColors.INDIGO,
            dependentField: "",
            id: "dpc",
          },
        ],
        // fieldType: "dropDown",
        filterElement: [
          {
            label: "Pending",
            value: "pending",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Paid",
            value: "paid",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        customStyle: "commonBadge"
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: false,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        displayinregisterForm: false,
        isBulkUploadField: false,
        show: false,
        field: "createdByName",
        mobile: true,
        filter: false,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: false,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        displayinregisterForm: false,
        globalSearchField: "false",
        isBulkUploadField: false,
        show: false,
        filter: false,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "item",
        fieldName: "item",
        type: "text",
        placeholder: "Task/Item",
        value: "",
        label: "Task/Item",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "item=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "item",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        customWidthClass: 'col-6',
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "description",
        fieldName: "description",
        type: "text",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        customWidthClass: 'col-6',
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "contactName",
        fieldName: "contactName",
        type: "text",
        placeholder: "Contact Name/Company",
        value: "",
        label: "Contact Name/Company",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        stringType: null,
        derivedValue: "contactName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "contactName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        customWidthClass: 'col-6',
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "Address",
        fieldName: "Address",
        type: "text",
        placeholder: "Address",
        value: "",
        label: "Address",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        stringType: null,
        derivedValue: "Address=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "Address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        customWidthClass: 'col-6',
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        customWidthClass: 'col-6',
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phone",
        fieldName: "phone",
        type: "text",
        placeholder: "Phone",
        value: "",
        label: "Phone",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        stringType: null,
        derivedValue: "phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        customWidthClass: 'col-6',
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "dueDate",
        fieldName: "dueDate",
        type: "date",
        placeholder: "Due Date",
        value: "",
        label: "Due Date",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        stringType: null,
        derivedValue: "dueDate=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        id: "dueDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        customWidthClass: 'col-6',
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "MM-DD-YYYY",
        fieldType: "Date",
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "frequency",
        fieldName: "frequency",
        type: "dropDown",
        placeholder: "Frequency",
        value: "",
        label: "Frequency",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        stringType: null,
        derivedValue: "frequency=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "frequency",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        customWidthClass: 'col-6',
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "DAILY",
            value: "daily",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "WEEKLY",
            value: "weekly",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "MONTHLY",
            value: "monthly",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "HALF YEARLY",
            value: "half yearly",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "YEARLY",
            value: "yearly",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "MULTIPLE YEARS",
            value: "multiple years",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "DAILY",
            value: "daily",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "WEEKLY",
            value: "weekly",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "MONTHLY",
            value: "monthly",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "HALF YEARLY",
            value: "half yearly",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "YEARLY",
            value: "yearly",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "MULTIPLE YEARS",
            value: "multiple years",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "amount",
        fieldName: "amount",
        type: "number",
        placeholder: "Amount",
        value: "",
        label: "Amount",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        stringType: null,
        derivedValue: "amount=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "amount",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        customWidthClass: 'col-6',
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "docs",
        fieldName: "docs",
        type: "fileUpload",
        placeholder: "Pic/Scan Doc",
        value: "",
        label: "Pic/Scan Doc",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        stringType: null,
        derivedValue: "docs=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "docs",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        customWidthClass: 'col-12',
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "other",
        maxFileSize: 1,
        isMultipleRequired: true,
        imagePath: "Paid History",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "ownerName",
        fieldName: "ownerName",
        type: "text",
        placeholder: "Owner/Assigned",
        value: "",
        label: "Owner/Assigned",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        stringType: null,
        derivedValue: "ownerName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "ownerName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        customWidthClass: 'col-6',
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        stringType: null,
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        customWidthClass: 'col-12',
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "Pending",
            value: "pending",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Paid",
            value: "paid",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Pending",
            value: "pending",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Paid",
            value: "paid",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        customWidthClass: 'col-6',
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "createdByName",
        type: "text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        displayinregisterForm: false,
        isBulkUploadField: false,
        show: true,
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        isAddFormHidden: true,
        isEditFormHidden: true,
        customWidthClass: 'col-6',

      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
        customWidthClass: 'col-6',
      },
      {
        name: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        displayinregisterForm: false,
        globalSearchField: "false",
        isBulkUploadField: false,
        show: true,
        filter: true,
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        isAddFormHidden: true,
        isEditFormHidden: true,
        customWidthClass: 'col-6',
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "item",
        fieldName: "item",
        type: "text",
        placeholder: "Task/Item",
        value: "",
        label: "Task/Item",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Task/Item",
        derivedValue: "item=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "item",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "item",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "description",
        fieldName: "description",
        type: "text",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "contactName",
        fieldName: "contactName",
        type: "text",
        placeholder: "Contact Name/Company",
        value: "",
        label: "Contact Name/Company",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        stringType: null,
        header: "Contact Name/Company",
        derivedValue: "contactName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "contactName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "contactName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Address",
        fieldName: "Address",
        type: "text",
        placeholder: "Address",
        value: "",
        label: "Address",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        stringType: null,
        header: "Address",
        derivedValue: "Address=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "Address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "Address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Email",
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone",
        fieldName: "phone",
        type: "text",
        placeholder: "Phone",
        value: "",
        label: "Phone",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        stringType: null,
        header: "Phone",
        derivedValue: "phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "phone",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "dueDate",
        fieldName: "dueDate",
        type: "date",
        placeholder: "Due Date",
        value: "",
        label: "Due Date",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        stringType: null,
        header: "Due Date",
        derivedValue: "dueDate=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        id: "dueDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "MM-DD-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "dueDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "frequency",
        fieldName: "frequency",
        type: "dropDown",
        placeholder: "Frequency",
        value: "",
        label: "Frequency",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        stringType: null,
        header: "Frequency",
        derivedValue: "frequency=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "frequency",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "DAILY",
            value: "daily",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "WEEKLY",
            value: "weekly",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "MONTHLY",
            value: "monthly",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "HALF YEARLY",
            value: "half yearly",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "YEARLY",
            value: "yearly",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "MULTIPLE YEARS",
            value: "multiple years",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "DAILY",
            value: "daily",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "WEEKLY",
            value: "weekly",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "MONTHLY",
            value: "monthly",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "HALF YEARLY",
            value: "half yearly",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "YEARLY",
            value: "yearly",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "MULTIPLE YEARS",
            value: "multiple years",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "frequency",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "amount",
        fieldName: "amount",
        type: "number",
        placeholder: "Amount",
        value: "",
        label: "Amount",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        stringType: null,
        header: "Amount",
        derivedValue: "amount=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "amount",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "amount",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "docs",
        fieldName: "docs",
        type: "fileUpload",
        placeholder: "Pic/Scan Doc",
        value: "",
        label: "Pic/Scan Doc",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        stringType: null,
        header: "Pic/Scan Doc",
        derivedValue: "docs=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "docs",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "other",
        maxFileSize: 1,
        isMultipleRequired: true,
        imagePath: "Paid History",
        textAlign: "center",
        show: true,
        field: "docs",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "ownerName",
        fieldName: "ownerName",
        type: "text",
        placeholder: "Owner/Assigned",
        value: "",
        label: "Owner/Assigned",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        stringType: null,
        header: "Owner/Assigned",
        derivedValue: "ownerName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "ownerName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "ownerName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        stringType: null,
        header: "Status",
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "Pending",
            value: "pending",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Paid",
            value: "paid",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Pending",
            value: "pending",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Paid",
            value: "paid",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        displayinregisterForm: false,
        isBulkUploadField: false,
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        displayinregisterForm: false,
        globalSearchField: "false",
        isBulkUploadField: false,
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.paidHistories}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const customButton = ({item, tableItem}) => {
    return (
      <>
        {
          item?.status !== "paid" && 
          <div onClick={() => dataTableRef?.current?.openFormModal(item, "edit", dataTableRef?.current?.dirty)}>
            <FontAwesomeIcon
                    className='genderIconAlignment'
                    color='#0e7490'
                    icon='pen'
                    data-toggle="tool-tip"
                    title='Edit'
                    style={{ color: '#17a2b8', width: '15', cursor: 'pointer', }}
            />
          </div>
        }
      </>
    )
  }



  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  const Header = styled.div`
      color: #111827;
      font-size: 1.4rem;
      font-weight: bold;
      font-family: "Inter" sans-serif;
      margin-bottom: 4px;
    `

  const tableFields = getTableFields();

  return (
    <div ref={ref}>
      <Container className="p-2">
        <Row className="w-100 g-4">
          <Header>
            <Link to={"/"+props.routeTo} onClick={props.closeFormModal}>View {props.type}</Link>
          </Header>
          {
            props?.originalTableFields.map(data => (
                !["Check Box", "Sno", "Actions", "updated", "updatedByName"].includes(data?.field) && selectedRow?.[data.field] && <Col key={data.field} sm={6} md={4} lg={3} xl={2}>
                  <InfoContainer header={data.header} value={selectedRow?.[data.field]} changeFieldValues={props?.changeFieldValues} col={data} item={selectedRow} />
                </Col>
              ))
          }

          {/**@DataTable Column*/}
          <Col sm={12}>
          {/**@PAIDHISTORIES HEADER */}
          <div className="d-flex align-items-center gap-1">
            <span>
              <FontAwesomeIcon
                color='#0e7490'
                icon="chart-line"
                data-toggle="tool-tip"
                title='Edit'
                style={{ color: '#e11d48', width: '50px', height:"20px", cursor: 'pointer', }}
                size={34}
              />
            </span>
            <span className="fw-bold" style={{fontSize: "1.1rem", fontFamily:'"Inter", sans-serif', color: "#1f2937", textTransform: "capitalize"}}>Paid Histories</span>
          </div>
          <DataTable
            onRef={ref => dataTableRef.current = ref}
            getTableFields={() =>getTableFields()}
            formFields={getFormFields}
            footerClassName="footer__bg_color"
            results={paidHistories}
            tableFields={tableFields}
            type={"Paid Histories"}
            apiUrl={apiCalls.paidHistories}
            formUrl={apiCalls.paidHistories}
            routeTo={apiCalls.duesAndSubscriptions}
            resultsFromParent
            displayViewOfForm="modal"
            disableRefresh={true}
            showFilterOptions={true}
            disableHeader={true}
            isDisablePaginator={true}
            disableClearFilter={true}
            disableSaveFilter={true}
            disableActionDropdown={true}
            disableTableScroll={true}
            tableScrollHeight={"830px"}
            className="dashboard__table"
            rowClassName="inter__fontfamily px-5 py-5"
            columnHeaderClassName="dashboard__column__header"
            customFormModalStyle={{width: "70vw"}}
            paidHistoriesRef={ref}
            customActionButtons={customButton}
						/>  

          </Col>
        </Row>
      </Container>

    </div>

  );
});


const InfoContainer = ({ header, value, changeFieldValues, item, col}) => {
  return ( item &&  
    <div className="d-flex flex-column gap-1 border-bottom-secondary px-2" style={{fontFamily:'"Inter", sans-serif'}}>
      <div style={{fontSize:".9rem", fontWeight:"600", color: "#94a3b8"}}>{header}</div>
      <div style={{fontSize: "1rem", fontWeight: "700", color: "#475569"}}>{value}</div>
      <Divider style={{margin: "5px"}}/>
    </div>
  )
}

export default PaidHistories;
