import { FileUpload } from 'primereact/fileupload';
import { useState, useEffect, useRef } from 'react';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../../containers/UI/ToasterMessage/toasterMessage';
import config from '../../../config/config';
import { Controller } from 'react-hook-form'; // Import Controller
import { InputSwitch } from 'primereact/inputswitch';
import { Link } from 'react-router-dom';

const DoFileUplaod = (props) => {
    const fileInputRef = useRef(null);
    const [isImageUploaded, setIsImageUploaded] = useState(false);

    const id = props.id ? props.id : props.name;
    let label = props.label ? props.label : '';
    let newFiles = props?.field?.value;
    let isMultiple = props.isMultiple ? props.isMultiple : false;
    let maxFileSize = props.maxFileSize ? props.maxFileSize : 2097152; // 2 MB
    let type = props.type;
    const maxFiles = 5;

    // Effect to check initial image presence in edit scenario
    useEffect(() => {
        setIsImageUploaded(newFiles && newFiles.length > 0);
    }, [newFiles]);

    const onSelect = (e) => {
        const selectedFiles = isMultiple ? e.files : e.files[0];
        const oversizedFiles = [e.files[0]].filter(file => file.size > maxFileSize);

        if (oversizedFiles.length > 0) {
            const bytesPerMB = 1024 * 1024;
            showToasterMessage(`File size should be less than ${maxFileSize / bytesPerMB} MB`, 'error');
            clearFileUpload();
        } else {
            uploadFilesToServer(selectedFiles);
        }
    }

    const upload = (e) => {
        const files = e.files;
        if (files.length > maxFiles) {
            showToasterMessage(`You can only upload ${maxFiles} files`, 'error')
            const fileInput = document.querySelector('.p-fileupload-content');
            if (fileInput) {
                fileInput.style.display = 'none';
            }
        } else {
            uploadFilesToServer(isMultiple ? e.files : e.files[0]);
        }
    }

    const uploadFilesToServer = (data) => {
        fetchMethodRequest('POST', props.url, data, 'upload', isMultiple)
            .then(async (response) => {
                if (response && response.respCode) {
                    let fileName = response?.fileName;
                    //Setting field value in form
                    if (props.onChange) {
                        props.onChange(response);
                    }
                    if (props.field && !isMultiple) {
                        let value = Array.isArray(fileName) ? fileName.map(file => file.name) : [fileName?.name ? fileName.name : fileName];
                        props.field.onChange(value);
                    } else {
                        let newFiles = fileName.map(file => file.name);
                        let existingFiles = props.field.value || [];
                        let allFiles = [...existingFiles, ...newFiles];
                        if (props.field) {
                            props.field.onChange(allFiles)
                        }
                        clearFileUpload();
                    }
                    if (props.type === 'profile') {
                        let data = JSON.parse(localStorage.getItem('loginCredentials'))
                        data.photo = fileName;
                        localStorage.setItem('loginCredentials', JSON.stringify(data))
                    }
                    setIsImageUploaded(true); 
                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                } else {
                    showToasterMessage('upload failed')
                }
            }).catch((err) => {
                return err;
            });
    }

    const checkImg = (img) => {
        if (img) {
            return config.imageFormats.some(x => img.toLowerCase().includes(x));
        }
        return false;
    };

    const deleteFile = (img) => {
        if (img) {
            let allFiles = newFiles.filter(file => file !== img)
            props.field.onChange(allFiles);
            clearFileUpload();
            setIsImageUploaded(false);
        }
    };

    const clearFileUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.clear()
        }
    };

    // const previewColSize = newFiles.length > 0 ? 'col-4' : '';
    const columnSize = newFiles?.length > 0 ? 'col-4' : ' ';
    return (
        <div >
            <div>
                <label>Patient Image Upload</label>
            </div>
            <div className="row">
                {/* File Upload Column */}
                <div className='col-4 d-flex align-items-center'>
                    <FileUpload
                        ref={fileInputRef}
                        mode={isMultiple ? 'advanced' : 'basic'}
                        name={props.name ? props.name : 'file'}
                        accept={props.acceptType ? props.acceptType : '*'}
                        maxFileSize={maxFileSize}
                        onSelect={!isMultiple ? onSelect : ''}
                        customUpload
                        uploadHandler={upload}
                        multiple={isMultiple ? isMultiple : false}
                        chooseLabel={props.label ? props.label : 'Choose File'}
                    />
                </div>

                {/* Image Preview Column */}
               {Array.isArray(newFiles) && newFiles.length>0 && <div className='col-4 d-flex justify-content-start align-items-center'>
                {newFiles && Array.isArray(newFiles) && newFiles.length > 0 && (
                        <div> 
                            {newFiles.map(img => (
                                <div className="row w-100">
                                    <div className="col-8 my-2">
                                        {props.type === 'profile' ?
                                            (
                                            <Link to={`${config.imgUrl}${img}`} target="_blank">
                                                <img src={config.imgUrl + img} style={{ width: '100%', height: '100%' }} alt="" />
                                            </Link>) :
                                            checkImg(img) ? (
                                                <Link to={`${config.imgUrl}${type.toLowerCase()}/${img}`} target="_blank">
                                                    <img src={config.imgUrl + `${type.toLowerCase()}/` + img} style={{ width: '80%' }} alt="" />
                                                </Link>
                                            ) : (
                                                <a href={`${config.imgUrl}${type.toLowerCase()}/${img}`} target='_blank' >
                                                    <img title={img} src={configImages?.[img?.split('.')?.[img?.split('.')?.length - 1]]} alt="" />
                                                </a>
                                            )}
                                    </div>
                                    <div className="col-3 my-3">
                                        <button className="btn text-danger" type='button' onClick={() => deleteFile(img)} style={{ padding: '5px 10px' }}>
                                            <i className="pi pi-trash" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>}

                {/* Show Image Toggle Column */}
                {props.item && props.item.showImageRadioButtons &&
                    <div className='col-4 d-flex justify-content-start align-items-center'>
                        <label htmlFor="showImage" className="mr-2">Show Profile Picture</label>
                        <div style={{ marginLeft: '15px' }}>
                            <Controller
                                name="showImage"
                                control={props.control}
                                defaultValue={props.getValues("showImage") ? props.getValues("showImage") : false}
                                render={({ field: { onChange, value } }) => (
                                    <InputSwitch
                                        id="showImage"
                                        onChange={(e) => { onChange(e.value) }}
                                        checked={value}
                                        disabled={!isImageUploaded} // Disable switch if no image uploaded
                                    />
                                )}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>



    )

}

export default DoFileUplaod;