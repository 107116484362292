import React, { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

export default function Signature(props) {

    const signatureRef = useRef(null);
    const [item, setItem] = useState(props.item ? props.item : {});
    const field = props.field;
    const fieldState = props.fieldState;
    const errors = props.error;

    useEffect(() => {
        if (props.onChange) {
            props.onChange(field.value);
        }

        if (field.value) {
            if (field.value !== signatureRef.current.toDataURL()) {
                signatureRef.current.fromDataURL(field.value);
            }
        } else {
            signatureRef.current.clear();
        }
    }, [field.value]);

    const handleSignatureChange = () => {
        const signatureData = signatureRef.current.toDataURL('image/png');
        field.onChange(signatureData);
    };

    return <div style={{ width: 400}}>

        <div className="d-flex justify-content-between" >
            <label>{item.label ? item.label : item.name}
                {item.required && <FontAwesomeIcon
                    color='red'
                    icon={faAsterisk}
                    style={{ width: '7px', marginLeft: "2px" }}
                />}
            </label>
            {!item.disabled && <i title="Refresh" className="pi pi-sync ml-5" onClick={() => field.onChange(null)}></i>}
        </div>
        {!item.disabled ?
            <div style={{ border: '2px solid #ced4da', display: 'inline-block' }}>
                <SignatureCanvas
                    ref={signatureRef}
                    penColor={item.penColor ? item.penColor : "blue"}
                    canvasProps={{ width: 400, height: 150 }}
                    onEnd={handleSignatureChange}
                    disabled={item.disabled ? item.disabled : false}
                    backgroundColor={item.backgroundColor ? item.backgroundColor : "white"}
                />
            </div>
            :
            <div style={{ backgroundColor: item.disabled ? '#dedede' : 'white', width: 400, height: 160, border: '2px solid black', display: 'inline-block' }}>
                {field.value && <img className="mb-5 w-100 h-100" src={field.value} />}
            </div>
        }
        <small className="text-danger">{props.fieldState.invalid ? props.errors?.[item?.name]?.message : ''}</small>
    </div>

}