/**@Packages */
import React, { useState, useEffect, useContext, useRef, lazy, Suspense } from "react";
import { useForm, Controller } from "react-hook-form";
import { Card, CardBody, Row, Col } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { faClock, faExclamationCircle, faBicycle, faHeartbeat, faHospitalUser, faStethoscope, faCalendarAlt, faCalendarPlus, faRunning, faFileInvoiceDollar, faDownload, faEnvelope, faClipboard } from "@fortawesome/free-solid-svg-icons";
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;
import dayjs from 'dayjs';
import DoMultiSelectField from "../../../Form/Fields/DoMultiSelectField";
import DoInputField from "../../../Form/Fields/DoInputField";
import { Dialog } from 'primereact/dialog';
import { DataTable as customDatatable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from "react-bootstrap";
/**@CustomImports */
import DataTable from "../../CommonDataTable/DataTable";
import fetchMethodRequest from "../../../../config/service";
import apiCalls from "../../../../config/apiCalls";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import config from "../../../../config/config";
import configImages from "../../../../config/configImages";
import Loader from "../../../App/Loader";
import "./InsuranceClaim.scss";
import StatusDetailsTable from "./StatusDetails";
import moment from "moment";
import EditableStatusTemplate from "./EditableStatus";
/**@Context */
import { GlobalContext } from "../../../App/App";
import { InputText } from "primereact/inputtext";
        
const InsuranceClaim = () => {
  const { control, handleSubmit, watch, reset, getValues, setValue, formState: { errors, isValid }, unregister } = useForm();
  const [placement, SetPlacement] = useState('bottomRight');
  const [size, setSize] = useState('large');
  const [inputValue, setInputValue] = useState("");
  const [results, setResults] = useState([]);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [statusFilter, setStatusfilter] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [globalSearchValue,setGlobalSearchValue] = useState("");
  const context = useContext(GlobalContext);

  useEffect(() => {
    getDataFromServer();
  }, [context?.patientData, statusFilter, dateRange,globalSearchValue]);

  const openDialog = (claim) => {
    setSelectedClaim(claim);
    setDialogVisible(true);
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Button onClick={() => openDialog(rowData)}>
        <FontAwesomeIcon icon={faClipboard} />
      </Button>
    );
  };


  const clearFilter = (e) => {
    reset();
  }

  const handleSave = (rowData, newStatus) => {
    const url = `${apiCalls.claimManagements}/${rowData.item._id}`;
    const updatedItem = { ...rowData.item, status: newStatus };

    fetchMethodRequest('PUT', url, updatedItem)
      .then(response => {
        if (response && response.respMessage) {
          showToasterMessage(response.respMessage, 'success');
          setStatusfilter([]);
          setDateRange([]);
          clearFilter();
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        } else {
          showToasterMessage('Unexpected response format', 'error');
        }
      })
      .catch(error => {
        console.error('Error during save operation:', error);
        showToasterMessage('An error occurred while saving. Please try again.', 'error');
      })
      .finally(() => {
        getDataFromServer();
      });
  };
  
  const editableStatusTemplate= (rowData) => {
    return (
      <div>
        <EditableStatusTemplate  handleSave={handleSave} getDataFromServer={getDataFromServer} rowData={rowData}/>
      </div>
    )
  }
  const statusDialog = (
    <Dialog header="Status Details" visible={dialogVisible} style={{ width: '70vw' }} onHide={() => setDialogVisible(false)} modal>
      {selectedClaim && <StatusDetailsTable statusDescription={selectedClaim.item.statusDescription} />}
    </Dialog>
  );
  
  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        name: "sentDate",
        type: "date",
        placeholder: "Sent Date",
        label: "Sent Date",
        width: 90,
        header: "Sent Date",
        derivedValue: "sentDate=undefined",
        actions: [],
        actionsNumber: [],
        id: "sentDate",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateDBFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "sentDate",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "patientId",
        type: "relateAutoComplete",
        placeholder: "Patient Name",
        label: "Patient Name",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Patient Name",
        derivedValue: "patientId=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "patientId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "Patient",
        searchApi: apiCalls.patients,
        isMultiple: false,
        textAlign: "Center",
        show: true,
        filter: true,
        field: "patientId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "subscriberId",
        type: "Text",
        placeholder: "Subscriber ID",
        label: "Subscriber ID",
        width: 120,
        header: "Subscriber ID",
        id: "subscriberId",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "subscriberId",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "carrierId",
        type: "relateAutoComplete",
        placeholder: "Carrier",
        label: "Carrier",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Carrier",
        derivedValue: "carrier=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "carrierId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "insuranceCompany",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "Insurance Claim",
        searchApi: apiCalls.patients,
        isMultiple: false,
        textAlign: "Center",
        show: true,
        filter: true,
        field: "carrierId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "status",
        fieldType: "customColumn",
        customColumn: editableStatusTemplate ,
        placeholder: "Status",
        label: "Status",
        width: 100,
        header: "Status",
        field: "status",
        sortable: false,
        filter: false,
        textAlign: "center",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "statusDescription",
        fieldType: "customColumn",
        customColumn: statusBodyTemplate,
        placeholder: "Status Description",
        label: "Status Description",
        width: 200,
        header: "Status Description",
        field: "statusDescription",
        sortable: false,
        filter: false,
        show: true,
        mobile: true,
        displayInSettings: true,
        textAlign: 'center',
        style: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
          paddingTop:'11px'
        }
      }
    ];
    return data;
  }


  let getDropdownMultiselect = (i, item) => {

    const onChange = (value) => {
      setStatusfilter(value);
    }
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoMultiSelectField
              markReq={item.required}
              input={field}
              id={field.id}
              item={item}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              label={item.label}
              options={item.options}
              optionLabel={item.optionLabel}
              placeholder={item.placeholder}
              onChange={onChange}
            />)}
        />
      </div>
    )
  }

  const statusOptions = [
    { label: 'Sent', value: 'Sent' },
    { label: 'Unsent', value: 'Unsent' },
    { label: 'Accept', value: 'Accept' },
    { label: 'Reject', value: 'Reject' }
  ];

  const rangePresets = [
    {
      label: 'Tomorrow',
      value: [dayjs().add(+1, 'd'), dayjs()],
    },
    {
      label: 'Next 7 Days',
      value: [dayjs().add(+7, 'd'), dayjs()],
    },
    {
      label: 'Next 14 Days',
      value: [dayjs().add(+14, 'd'), dayjs()],
    },
    {
      label: 'Next 30 Days',
      value: [dayjs().add(+30, 'd'), dayjs()],
    },
    {
      label: 'Next 90 Days',
      value: [dayjs().add(+90, 'd'), dayjs()],
    },
    {
      label: 'Next 180 Days',
      value: [dayjs().add(+180, 'd'), dayjs()],
    },
  ];

  const markRequired = () => (
    <FontAwesomeIcon
      color="red"
      icon={faAsterisk}
      style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
    />
  );
  

  const handleGlobalSearchChange = (e) => {
    setGlobalSearchValue(e?.target?.value);
  };

  // This function replaces getDefaultInputField for global search
  const renderGlobalSearch = () => {
    return (
      <div className="insurance-claim-global-search">
      <Controller
        name="globalSearch"
        control={control}
        render={({ field }) => (
          <div className="p-inputgroup">
          <span className="p-inputgroup-addon">
            <i className="pi pi-search"></i>
          </span>
          <InputText
            {...field}
            onChange={(e) => {
              field.onChange(e); 
              handleGlobalSearchChange(e);
            }}
            placeholder="Search..."
            className="w-full"
          />
         
        </div>
        )}
      />
      </div>
    );
  };

  /**@MarkRequired Component to render the {*} for required fields */
  const MarkRequired = () => {
    return <div className="ms-1">
      <FontAwesomeIcon
        color='red'
        icon={faAsterisk}
        style={{ width: '7px', marginLeft: "2px" }}
      />
    </div>
  }

  const getDataFromServer = () => {
    let filterCriteria = {
      sortfield: "created",
      direction: "desc",
      criteria: []
    };

    if (context?.patientData && context.patientData._id) {
      filterCriteria.criteria.push({
        key: 'patientId',
        value: context.patientData._id,
        type: 'eq'
      });
    }

    if (Array.isArray(statusFilter) && statusFilter.length > 0) {
      filterCriteria.criteria.push({
        key: 'status',
        value: statusFilter,
        type: 'in'
      });
    }
    
    if (globalSearchValue) {
      filterCriteria.globalSearch = {
        value: globalSearchValue,
        type: 'user'
      };
    }
    const isValidStartDate = dateRange[0] && dayjs(dateRange[0]).isValid();
    const isValidEndDate = dateRange[1] && dayjs(dateRange[1]).isValid();

    if (isValidStartDate && isValidEndDate) {
      filterCriteria.criteria.push(
        {
          key: 'sentDate',
          value: dateRange[0],
          type: 'gte'
        },
        {
          key: 'sentDate',
          value: dateRange[1],
          type: 'lte'
        }
      );
      filterCriteria.isDateSearch = true;
    }

    
    const url = `${apiCalls.claimManagements}?filter=${JSON.stringify(filterCriteria)}`;

    fetchMethodRequest('GET', url).then((res) => {
      if (res && res.claimManagements) {
        // Format the sentDate for each item
        const formattedResults = res.claimManagements.map(claim => ({
          ...claim,
          sentDate: claim.sentDate ? moment(claim.sentDate).format('MM-DD-YYYY') : null
        }));
        setResults(formattedResults);
      }
    }).catch(error => {
      console.error("Failed to fetch data:", error);
      showToasterMessage({ severity: 'error', summary: 'Data Fetch Error', detail: 'Failed to load insurance claims.' });
    });
  }


  const handleDateRangeChange = (field, dates, dateStrings) => {
    const formattedDates = dateStrings.map((date, index) => {
      if (index > 0) return dayjs(date).endOf('day').format('YYYY/MM/DD HH:mm:ss [GMT]Z');
      else return dayjs(date).format("YYYY/MM/DD HH:mm:ss [GMT]Z");
    });
    setDateRange(formattedDates);
    field.onChange(formattedDates);
  };
  
  return (
    <div className="insurance-claim-container">
      <Card className="cardForListMargin">
        <CardBody className="tableCardBody h-100">
          <h4 style={{ fontWeight: "bold", marginBottom: '10px' }}>Insurance Claim Management</h4>
          <Row className="insurance-claim-row">
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <div className="insurance-claim-dropdown" style={{marginRight:'10px'}}>
              {getDropdownMultiselect(1, {
                name: "filterby",
                type: "dropDown",
                placeholder: "Select Status",
                label: " ",
                header: "Filter by",
                options: statusOptions,
                optionLabel: "label",
                required: false
              })}
            </div>
              <Space direction="vertical" size={12}>
                <Controller
                  name="dateRange"
                  control={control}
                  rules={{ required: 'Date range is required' }}
                  render={({ field, fieldState }) => (
                    <>
                      <RangePicker
                        onChange={(dates, dateStrings) => handleDateRangeChange(field, dates, dateStrings)}
                        format="MM-DD-YYYY"
                        placement={placement}
                        size={size}
                        presets={rangePresets}
                      />
                      {fieldState.error && <p style={{ color: 'red', marginTop: '8px', fontSize: '0.775em' }}>{fieldState.error.message}</p>}
                    </>
                  )}
                />
              </Space>
              <div className="insurance-claim-global-search"style={{marginLeft:'5px'}}>
                {renderGlobalSearch()}
              </div>
          </div>
        </Row>
          <div>
            <DataTable
              getTableFields={getTableFields}
              tableFields={getTableFields}
              formFields={()=>[]}
              type={"Insurance Claim Management"}
              displayViewOfForm="screen"
              disableRefresh={true}
              showFilterOptions={true}
              disableHeader={true}
              isDisablePaginator={true}
              results={results}
              noSelectionMode={" "}
              disableClearFilter={true}
              disableSaveFilter={true}
              resultsFromParent
            />
          </div>
          {statusDialog}
        </CardBody>
      </Card>
    </div>
  );
}

export default InsuranceClaim;




// const getDefaultInputField = (item) => {
//   const storeGlobalSearchValue = (e) => {
//     setGlobalSearchValue(e.value);
//   };

//   return (
//     <Controller
//       name={item.name}
//       control={control}
//       render={({ field, fieldState }) => (
//         <DoInputField
//           markReq={item.required}
//           field={field}
//           item={item}
//           id={field.id}
//           name={field.name}
//           label={item.label}
//           type={item.type ? item.type : "text"}
//           fieldState={fieldState}
//           errors={errors}
//           placeholder={item.placeholder ? item.placeholder : null}
//           onChange={storeGlobalSearchValue}
//         />
//       )}
//     />
//   );
// };

{/* {getDefaultInputField({
      name: "globalSearch",
      type: "text",
      placeholder: "Search...",
      label: " ",
      required: false
    })} */}